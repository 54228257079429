import { 
    getStorage, 
    ref, 
    uploadBytes, 
    getDownloadURL,
    deleteObject,
    
} from "firebase/storage";
import app from "./trekit-config";

const storage = getStorage(app);

export async function uploadProfilePicture(uid, fileBlob) {
    if (uid || uid.length > 0) {
        let storageRef = ref(storage, `profile/${uid}`);
        const snapshot = await uploadBytes(storageRef, fileBlob);
        if (!snapshot) {
            throw new Error("Upload failed!");
        }

        const filePath = await getDownloadURL(snapshot.ref);
        const downloadURL = await filePath;

        return downloadURL;
    }
}

export async function updateCategoryImage(categoryName, fileBlob) {
    removeCategoryImage(categoryName);
    const newImageRef = ref(storage, `categories/${categoryName.toLowerCase()}.png`);
    const uploadRes = await uploadBytes(newImageRef, fileBlob);
    if(!uploadRes){
        throw new Error('Upload failed!');
    }
    const downloadUrl = await getDownloadURL(uploadRes.ref);
    return downloadUrl;
}

export async function updateCategoryMapPin(categoryName, fileBlob) {
    removeCategoryMapPin(categoryName);
    const newPinRef = ref(storage, `map-pins/${categoryName.toLowerCase()}-pin.png`);
    const uploadRes = await uploadBytes(newPinRef, fileBlob);
    if(!uploadRes){
        throw new Error('Upload failed!');
    }
    const downloadUrl = await getDownloadURL(uploadRes.ref);
    return downloadUrl;
}

export async function removeCategoryImage(categoryName) {
    const categoryRef = ref(storage, `categories/${categoryName.toLowerCase()}.png`);
    await deleteObject(categoryRef);
}

export async function removeCategoryMapPin(categoryName) {
    const pinRef = ref(storage, `map-pins/${categoryName.toLowerCase()}-pin.png`);
    await deleteObject(pinRef);
}

export async function uploadCategoryImage(categoryName, fileBlob) {
    const newImageRef = ref(storage, `categories/${categoryName.toLowerCase()}.png`);
    const uploadRes = await uploadBytes(newImageRef, fileBlob);
    if(!uploadRes){
        throw new Error('Upload failed!');
    }
    const downloadUrl = await getDownloadURL(uploadRes.ref);
    return downloadUrl;
}

export async function uploadCategoryMapPin(categoryName, fileBlob) {
    const newPinRef = ref(storage, `map-pins/${categoryName.toLowerCase()}-pin.png`);
    const uploadRes = await uploadBytes(newPinRef, fileBlob);
    if(!uploadRes){
        throw new Error('Upload failed!');
    }
    const downloadUrl = await getDownloadURL(uploadRes.ref);
    return downloadUrl;
}