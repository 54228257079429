import React from 'react';
import './index.scss';


const Loader = () => {
    return (
        <div className="loader-rings">
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    );
};

export default Loader;