import React, { useState }  from 'react';

/* ---------- Constants ---------- */
import * as Notifications   from 'constants/notification-types';

/* ---------- Redux ---------- */
import { useDispatch, useSelector }  from 'react-redux';

/* ---------- Components ---------- */
import Button   from 'components/UI/Button';

/* ---------- Assets ---------- */
import { IoCopyOutline } from 'react-icons/io5';
import Actions from 'redux/actions';
import useLanguage from 'hooks/useLanguage';


const TableRow = (props) => {
    const texts = useSelector(store => store.gd.translations);
    const { Key, languages = [], onClick, btnText, Translations = {}, onRemove, resetFields = false } = props;
    const [localKey, setLocalKey] = useState("");
    const [translations, setTranslations] = useState(Translations);
    const dispatch = useDispatch();
    const activeLanguage = useSelector(store => store.localStorage.language);

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        let updatedTranslations = {...translations };
        updatedTranslations[name] = value;
        setTranslations(updatedTranslations);
    };
    
    const onKeyChange = (e) => {
        const { value } = e.target;
        setLocalKey(value);
    };
    
    const onSubmit = () => {
        const key = Key ? Key:localKey;
        if(resetFields){
            setTranslations({});
            setLocalKey("");
        }
        onClick(Key ? Key:localKey, translations, !Key);
    };
    

    const copyToClipboard = () => {
        navigator.clipboard.writeText(Key);
        dispatch(Actions.addNotification({
            title: texts[activeLanguage]['web_cms_notification_copy_title'],
            text: texts[activeLanguage]['web_cms_notification_copy_text']?.replace('[_]', Key),
            status: Notifications.INFO,
            duration: 3000
        }));
    };
    
    
    const keyMaxWidth = 100 / (2 + languages.length);
    return (
        <tr>
            <td style={{maxWidth: keyMaxWidth + '%' }}>
                <span className='key'>
                {Key ? Key:<textarea value={localKey} onChange={onKeyChange} rows={3}/>}
                </span>
                {Key && (<div className='copy-to-clipboard'>
                    <IoCopyOutline onClick={copyToClipboard} size={20} />
                </div>)}
            </td>
            {languages.map((lang, idx) => (
                <td key={`${lang}_${idx}`}>
                    <textarea value={translations[lang] ? translations[lang]:""} onChange={onFieldChange} name={lang}  rows={3}/>
                </td>
            ))}
            <td>
                <Button onClick={onSubmit}>{btnText}</Button>
                {onRemove && <Button onClick={() => onRemove(Key)} className="remove-btn">{texts[activeLanguage]['web_cms_buttons_remove']}</Button>}
            </td>
        </tr>
    );
};

export default TableRow;