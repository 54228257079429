import Api from 'api/requests';
import useLanguage from 'hooks/useLanguage';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { generateUniqueKey } from 'utilites';
import './index.scss';


const ReportsManager = (props) => {
    const location = useLocation();
    const texts = useLanguage();
    const reportTypes = useSelector(store => store.gd?.parameters?.reportTypes);
    const [reports, setReports] = useState([]);

    useEffect(() => {
        const URL = location.pathname;
        const props = {
            payload: {}
        };

        if(URL.includes('users')){
            props.payload.type = reportTypes?.USER;
        } else if(URL.includes('treks')) {
            props.payload.type = reportTypes?.TREK;
        } else if(URL.includes('comments')) {
            props.payload.type = reportTypes?.COMMENT;
        }

        props.onSuccess = (res) => {
            setReports(res);
        };
        if(props.payload.type){
            Api.getReportsByType(props);
        }
    }, [location.pathname]);


    const getRow = (item, idx) => {
        const key = generateUniqueKey(8);
        const type = item.targetType;
        const date = new Date(item.reportedOn);
        let dateString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}\n${date.getHours()}:${date.getMinutes()}`;
        dateString = dateString.split('\n');
        dateString = [
            dateString[0],
            <br key={'space-'+idx} />,
            dateString[1]
        ]
        switch(type) {
            case reportTypes.USER:
                return (
                    <tr key={key}>
                        <td>{dateString}</td>
                        <td>{item.reportedBy?.displayName}</td>
                        <td>{texts[item.reportType]}</td>
                        <td>{item.reportTarget?.displayName}</td>
                        <td>{item.message}</td>
                    </tr>
                    );
            case reportTypes.TREK:
                return (
                    <tr key={key}>
                        <td>{dateString}</td>
                        <td>{item.reportedBy?.displayName}</td>
                        <td>{texts[item.reportType]}</td>
                        <td>{item.reportTarget?.name}</td>
                        <td>{item.message}</td>
                    </tr>
                    );
            case reportTypes.COMMENT:
                return (
                    <tr key={key}>
                        <td>{dateString}</td>
                        <td>{item.reportedBy?.displayName}</td>
                        <td>{texts[item.reportType]}</td>
                        <td><span className='bold'>{item.reportTarget.sender?.displayName}: </span>{item.reportTarget.message}</td>
                        <td>{item.message}</td>
                    </tr>
                    );
        }
    }
    


    return (
        <div className='cms-reports-manager'>
            <table className='reports-table'>
                <thead>
                    <td>{texts['web_column_reported_on']}</td>
                    <td>{texts['web_column_reported_by']}</td>
                    <td>{texts['web_column_report_type']}</td>
                    <td>{texts['web_column_report_target']}</td>
                    <td>{texts['web_column_report_message']}</td>
                </thead>
                <tbody>
                    {reports?.map(getRow)}
                </tbody>
            </table>
        </div>
    );
};

export default ReportsManager;