import { useEffect } from "react";

const useTitle = (props, additional) => {
  const { title } = props;

  useEffect(() => {
    if(additional) {
      document.title = additional + " | " + title;
    } else {
      document.title = title;
    }
  }, [title, additional]);


  return title;
};

export default useTitle;
