import useLanguage from "hooks/useLanguage";
import React, { useState, useRef, useEffect } from "react";
import { vw } from "utilites";
import "./index.scss";

const FloatTextArea = (props) => {
    const {
        label = '',
        value,
        onChange = () => {},
        errMsg,
        type = "text",
        name,
        rules = [],
        autoComplete = "off",
        maxLength,
        showError = false,
        className = "",
        required = false,
        disabled = false,
    } = props;

    const texts = useLanguage();
    // const [errorVisible, setErrorVisible] = useState(showError);
    const [isFocused, setIsFocused] = useState(false);
    // const [labelSize, setLabel] = useState({
    //     width: 0,
    //     height: 0,
    // });
    const inputRef = useRef();
    const labelRef = useRef();

    useEffect(() => {
        if (value !== "" && value !== undefined) {
            setIsFocused(true);
        } else {
            if (document.activeElement !== inputRef.current) {
                setIsFocused(false);
            }
        }
    }, [value]);

    useEffect(() => {
        let fontSize = getComputedStyle(labelRef.current).fontSize;
        fontSize = parseFloat(fontSize);

        // setLabel({
        //     width: vw(12 * 0.6 * (label.length + 2)) + 'vw',
        //     height: fontSize,
        // });
    }, [label.length]);

    // useEffect(() => {
    //     setErrorVisible(showError);
    // }, [showError]);

    const onBlur = () => {
        if (
            inputRef.current.value === "" ||
            inputRef.current.value === undefined
        ) {
            setIsFocused(false);
        }
        typeof props.onBlur === 'function' && props.onBlur();
    };

    const onLabelClick = () => {
        inputRef.current.focus();
    };

    const onFocus = () => {
        setIsFocused(true);
        typeof props.onFocus === 'function' && props.onFocus();
    };

    const onValueChange = (e) => {
        if(rules.length > 0) {
            onChange(e, rules);
        } else {
            const { name, value } = e.target;
            onChange(name, value);
        }
    };

    const floatClass = isFocused ? "active" : "";
    const errorClass = showError ? "error" : "";
    return (
        <div className={"float-textarea-wrapper " + className}>
            {/* <span
                className={`float-label-background ${floatClass}`}
                style={{
                    width: labelSize.width - 2,
                    height: labelSize.height - 2,
                }}
            ></span> */}
            <span
                ref={labelRef}
                className={`float-label ${floatClass}`}
                onClick={onLabelClick}
            >
                {label}
                {required && <span className="required-indicator">*</span>}
            </span>
            <div className="textarea-wrapper">
                <textarea
                    ref={inputRef}
                    className={"float-textarea " + errorClass}
                    type={type}
                    autoComplete={autoComplete}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    name={name}
                    onChange={onValueChange}
                    value={value}
                    maxLength={maxLength}
                    disabled={disabled}
                />
            </div>
            {showError && (
                <div className={`error-wrapper`}>{texts[errMsg]}</div>
            )}
        </div>
    );
};

export default FloatTextArea;
