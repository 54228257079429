import React, { useEffect, useState } from 'react';
import Api from 'api/requests';
import './index.scss';
import { useSelector } from 'react-redux';
import useLanguage from 'hooks/useLanguage';


const ContactEntries = (props) => {
    const [contacts, setContacts] = useState([]);
    const texts = useLanguage();
    const userData = useSelector(store => store.userData);

    useEffect(() => {
        if(userData){
            const onSuccess = (data) => {
                const res = [];
                for(const entry of data) {
                    const temp = {
                        _id: entry._id,
                        fullname: entry.fullname,
                        email: entry.email,
                        message: entry.message
                    };
                    res.push(temp);
                }
                setContacts(res);
            };
            const props = {
                onSuccess
            };
            Api.getContacts(props);
        }
    }, [userData]);

    return (
        <div className='cms-contact-entries-wrapper'>
            <table className='contant-entries-table'>
                <thead>
                    <td>{texts['field_full_name']}</td>
                    <td>{texts['field_email']}</td>
                    <td>{texts['field_message_cms']}</td>
                </thead>
                <tbody>
                    {contacts.map((contact) => (
                        <tr>
                            {Object.keys(contact).map((key) => key !== '_id' ? <td>{contact[key]}</td>:<></>)}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ContactEntries;