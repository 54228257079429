import React, { useState }   from 'react';
import Api from 'api/requests';

/* ---------- Redux ---------- */
import { useDispatch, useSelector } from 'react-redux';
import Actions                      from 'redux/actions';

/* ---------- Components ---------- */
import Button   from 'components/UI/Button';
import TableRow from './components/table-row';

/* ---------- Constants ---------- */
import popupTypes           from 'constants/popup-types';
import * as Notifications   from 'constants/notification-types';

/* ---------- Assets ---------- */
import './index.scss';
import useTitle from 'hooks/useTitle';
import useLanguage from 'hooks/useLanguage';

const UPPERCASE = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

const Translations = (props) => {
    useTitle(props);
    const dispatch = useDispatch();
    const translations = useSelector(store => store.gd.translations);
    const sortedCodes = Object.keys(translations).sort((curr, prev) => parseInt(translations[curr].ORDER_NUM) < parseInt(translations[prev].ORDER_NUM) ? -1:1);
    const [query, setQuery] = useState("");
    const texts = useLanguage();

    const handleAdd = (key, Translations) => {
        const formatedKey = key.toLowerCase().replaceAll(" ", "_");
        const isNew = !Object.keys(translations.en).includes(key);
        
        const onSuccess = () => {
            let translationKey = isNew ? 'web_cms_notification_success_translations_added':'web_cms_notification_success_translations_updated';
            dispatch(
                Actions.addNotification({
                    text: texts[translationKey]?.replace('[_]', formatedKey),
                    status: Notifications.SUCCESS,
                    duration: 3000,
                    onClick: () => {
                        navigator.clipboard.writeText(formatedKey);
                        dispatch(Actions.addNotification({
                            title: texts['web_cms_notification_copy_title'],
                            text: texts['web_cms_notification_copy_text']?.replace('[_]', formatedKey),
                            status: Notifications.INFO,
                            duration: 3000
                        }));
                    }
                })
            );
        }
        
        const onError = (error) => {
            dispatch(
                Actions.addNotification({
                    text: error,
                    status: Notifications.ERROR,
                    duration: 3000,
                })
            );
        };
        
        const props = {
            onError,
            onSuccess,
            payload: {
                key: formatedKey,
                translations: Translations
            }
        };

        Api.addTranslation(props);
    }

    const handleRemove = (key) => {
        
        const onSuccess = () => {
            dispatch(
                Actions.addNotification({
                    title: "Success",
                    text: `${key} removed!`,
                    status: Notifications.SUCCESS,
                    duration: 3000,
                })
            );
        };

        const props = {
            onSuccess,
            payload: {
                key
            }
        }

        Api.removeTranslation(props);
    }
    

    const onQueryChange = (e) => {
        const { value } = e.target;
        setQuery(value);
    }
    
    const openLanguagesManager = () => {
        dispatch(Actions.addPopup({
            type: popupTypes.LANGUAGES
        }))
    }
    

    return (
        <div className='translations-wrapper'>
            <div className="search-wrapper">
                <label htmlFor='query' className='label'>{texts['web_cms_translations_search']}</label>
                <input type={"text"} onChange={onQueryChange} value={query} className="input" />
            </div>
            <div className="control-btns">
                <Button onClick={openLanguagesManager} >{texts['web_cms_translations_buttons_manage_languages']}</Button>
            </div>
            <table>
                <thead>
                    <tr>
                        <td>{texts['web_cms_table_column_key']}</td>
                        {Object.keys(translations).map((lang, idx) => (
                            <td key={`${lang}_${idx}`}>{translations[sortedCodes[idx]].LANGUAGE}</td>
                        ))}
                        <td>{texts['web_cms_table_column_actions']}</td>
                    </tr>
                </thead>
                <tbody>
                    <TableRow
                        btnText={texts['web_cms_buttons_add']}
                        languages={sortedCodes}
                        onClick={handleAdd}
                        resetFields
                    />
                    {Object.keys(translations.en).filter((key) => key.includes(query)).map((key, idx) => (
                        !UPPERCASE.includes(key.charAt(0)) ? (
                        <TableRow
                            key={`${key}_${idx}`}
                            btnText={texts['web_cms_buttons_save']}
                            languages={sortedCodes}
                            onClick={handleAdd}
                            Key={key}
                            Translations={getTranslations(translations, key)}
                            onRemove={handleRemove}
                        />
                        ):undefined
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Translations;

const getTranslations = (translations, key) => {
    const res = {};
    for(const k in translations){
        res[k] = translations[k][key];
    }
    return res;
}
