import Api from 'api/requests';
import Button from 'components/UI/Button';
import FloatInput from 'components/UI/FloatInput';
import useInput from 'hooks/useInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'redux/actions';
import { capitalize } from 'utilites';
import './index.scss';


const AddAlert = (props) => {
    const { payload = {} } = props;
    const { data = {} } = payload;
    const dispatch = useDispatch();
    const [form, onChangeForm] = useInput(true, data);
    const languages = useSelector(store => store.gd.translations);
    const [selectedLanguage, setSelectedLanguage] = useState(Object.keys(languages)[0]);
    const [formDisabled, setFormDisabled] = useState(true);
    const alertTypes = useSelector(store => store.gd.parameters.alertTypes);

    useEffect(() => {
        if(form.type){
            setFormDisabled(false);
        }
    }, [form]);

    const getColor = () => {
      if(!form.type){
        return '';
      }
      return alertTypes?.[form.type]?.backgroundColor;
    };

    const languageChangeHandler = (e) => {
      const { value } = e.target;
      setSelectedLanguage(value);
    };

    const onSave = (e) => {
        e.preventDefault();
        let wantToFix = false;
        if(!form.title){
            alert('Insert a title')
            return;
        }
        Object.keys(languages).forEach(lang => {
            if(!form.title[lang] || form.title[lang] === ''){  
                if(!window.confirm('Are you sure you want to continue without a title?\n('+languages[lang].LANGUAGE+')')){
                    wantToFix = true;
                }
            }
        });
        if(wantToFix){
            return;
        }
        if(!form.description){
            alert('Insert a title')
            return;
        }
        Object.keys(languages).forEach(lang => {
            if(!form.description[lang] || form.description[lang] === ''){  
                if(!window.confirm('Are you sure you want to continue without a message?\n('+languages[lang].LANGUAGE+')')){
                    wantToFix = true;
                }
            }
        });
        if(wantToFix){
            return;
        }

        const updatedForm = JSON.parse(JSON.stringify(form));
        for(const key in updatedForm){
            if(typeof updatedForm[key] === 'string'){
                updatedForm[key] = updatedForm[key].trim();
            } else {
                for(const subKey in updatedForm[key]){
                    if(typeof updatedForm[key][subKey] === 'string'){
                        updatedForm[key][subKey] = updatedForm[key][subKey].trim();
                    }
                }
            }
        }


        const onSuccess = () => {
          dispatch(Actions.removePopup());
        };
        

        const props = {
            onSuccess,
            payload: {
                ...updatedForm
            }
        };
        Api.addAlert(props);
    };
    
    
    const getField = (field) => {
      if(form[field]){
        if(form[field][selectedLanguage]){
            return form[field][selectedLanguage];
        }
      }
      return '';
    };

    const highlightType = formDisabled ? 'highlight':'';
    return (
        <div className='add-alert-popup'>
            <div className='add-alert-color-strip' style={{ backgroundColor: getColor() }}/>
            <div className='add-alert-header'>
                <div className='add-alert-title'>
                    Add an Alert
                </div>
                <div className='add-alert-header-settings'>
                    <div className='add-alert-language'>
                        <label htmlFor='language'>Change language: </label>
                        <select
                            name='language'
                            defaultValue={selectedLanguage} 
                            onChange={languageChangeHandler}
                        >
                            {Object.keys(languages).map((lang, idx) => (
                                <option value={lang} key={`system-language-${lang}`}>
                                    {languages[lang].LANGUAGE}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={`add-alert-type`}>
                        <label htmlFor='type'>Type: </label>
                        <select 
                            className={highlightType}
                            name='type'
                            defaultValue={form.type}
                            onChange={onChangeForm}
                        >
                            <option value='' disabled selected={!form.type}>----</option>
                            {Object.keys(alertTypes).map((type, idx) => (
                                <option value={type} key={`alert-type-${idx}-${type}`}>
                                    {capitalize(type)}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <form 
                className='add-alert-form' 
                onChange={onChangeForm}
                onSubmit={onSave}
            >
                <FloatInput 
                    disabled={formDisabled}
                    className='add-alert-input'
                    name={'title.'+selectedLanguage}
                    value={getField('title')}
                    label={`Enter a title (${languages[selectedLanguage]?.LANGUAGE})`}
                />
                <textarea 
                    disabled={formDisabled}
                    name={`description.${selectedLanguage}`} 
                    value={getField('description')} 
                    placeholder={`Enter the message (${languages[selectedLanguage]?.LANGUAGE})`}
                />
                <FloatInput
                    disabled={formDisabled}
                    className='add-alert-input'
                    name='link'
                    value={form.link}
                    label={'Enter a URL (optional)'}
                />
                    <label className='add-alert-expiry'>
                        Expiry date:
                        <input 
                            disabled={formDisabled}
                            type='date' 
                            name='expiry' 
                            pattern='\d{2}-\d{2}-\d{4}'
                            value={form.expiry}
                        />
                    </label>
                <Button
                    disabled={formDisabled}
                    className='add-alert-save-button'
                >
                    Save
                </Button>
            </form>
        </div>
    );
};

export default AddAlert;