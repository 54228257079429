import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

/* ---------- Constants ---------- */
import * as ROUTES  from 'constants/routes';
import authRoutes from 'constants/auth-routes';

const PrivateRoute = (props) => {
    const userData = useSelector(store => store.userData);
    return userData ? <AuthRoute/> : <Navigate to={ROUTES.CMS_SUBS.root.subs.login.path} />
};

export default PrivateRoute;

const AuthRoute = () => {
    const location = useLocation();
    return authRoutes.includes(location.pathname) ? <Navigate to={ROUTES.CMS_SUBS.root.subs.home.path}/>:<Outlet/>
  
}
