class Timer {
    constructor(callback, delay){
        this.callback = callback;
        this.remaining = delay;
        this.running = false;
        this.id = null;
        this.started = false;
        this.start();
    }

    start() {
        this.running = true;
        this.started = new Date();
        this.id = setTimeout(this.callback, this.remaining)
    }

    pause() {
        this.running = false;
        clearTimeout(this.id);
        this.remaining -= new Date() - this.started;
    }

    getTimeLeft() {
        if(this.running){
            this.pause();
            this.start();
        }
        return this.remaining;
    }

    isRunning() {
        return this.running;
    }
};
 
export default Timer;