import { CMS_SUBS } from 'constants/routes';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';


const Comment = ({ _id, sender, message, postedOn, likes }) => {
    const navigate = useNavigate();

    const goToAuthor = () => {
        navigate(`${CMS_SUBS.content.subs.users.path}/${sender._id}`);
    };

    return (
        <div className='comment-wrapper'>
            <div className="comment-left-side" onClick={goToAuthor}>
                <img src={sender.photoUrl} alt="user image" className="profile-photo" />
            </div>
            <div className="comment-right-side">
                <div className="comment-header">
                    <div className="sender-name" onClick={goToAuthor}>{sender.displayName}</div>
                    <div className="likes">{likes.length}</div>
                </div>
                <div className="comment-content">{message}</div>
            </div>
        </div>
    );
};

export default Comment;