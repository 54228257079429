import Api from 'api/requests';
import AutoComplete from 'components/UI/AutoComplete';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination, Navigation } from 'swiper';

import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment'

import './index.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { useNavigate, useParams } from 'react-router-dom';
import { CMS_SUBS } from 'constants/routes';
import useTitle from 'hooks/useTitle';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import useLanguage from 'hooks/useLanguage';
import FloatInput from 'components/UI/FloatInput';
import FloatTextArea from 'components/UI/FloatTextArea';
import Icon from '@mdi/react';
import { mdiLock, mdiLockOpenVariant } from '@mdi/js';
import Switch from 'components/UI/Switch';
import TreksCounter from 'pages/CMS/components/treks-counter';
import Button from 'components/UI/Button';
import Comment from './components/comment';

const Treks = (props) => {
    useTitle(props);
    const texts = useLanguage();
    const timerRef = useRef();
    const swiperRef = useRef();
    const params = useParams();
    const navigate = useNavigate();
    const [options, setOptions] = useState([]);
    const [trek, setTrek] = useState(null);
    const [query, setQuery] = useState("");
    const [editable, setEditable] = useState(false);

    useEffect(() => {
        if (params.trekId) {
            const onSuccess = (response) => {
                setTrek(response);
            }
            const props = {
                onSuccess,
                payload: {
                    id: params.trekId,
                }
            };

            Api.getTrekById(props);
        }
    }, [params]);

    const onChange = (name, value) => {
        setQuery(value);

        if(value.length > 3) {
            const fetchOptions = () => {
                const onSuccess = (response) => {
                    setOptions(response);
                }

                const props = {
                    onSuccess,
                    payload: {
                        query: value
                    }
                }; 

                Api.getTreksByQuery(props);
                
            }
            
            debounce(fetchOptions, 550);
        } else {
            setOptions(null);
        }
    };

    const debounce = (callback, timeout) => {
        if(timerRef.current){
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(callback, timeout);
    };
    
    const onSelect = (id) => {
        navigate(`${CMS_SUBS.content.subs.treks.path}/${id}`);
    };
    
    const goToAuthor = () => {
        navigate(`${CMS_SUBS.content.subs.users.path}/${trek.author._id}`);
    };

    
    const formatOptions = (options) => {
        const res = [];
        for(const i in options) {
            res.push({
                text: options[i].name,
                _id: options[i]._id,
            });
        }
        return res;
    };

    const trekDetailsChangeHandler = (name, value) => {
        const temp = JSON.parse(JSON.stringify(trek));
        temp[name] = value;
        setTrek(temp);
    };

    const trekDetailsSaveHandler = () => {
        const onSuccess = (data) => {
            setEditable(false);
        };
        const props = {
            onSuccess,
            payload: {
                _id: trek._id,
                name: trek.name,
                description: trek.description,
                isAccessible: trek.isAccessible,
            }
        };
        Api.updateTrekDetails(props);
    };
    

    const date = moment(trek?.postedOn)?.format('DD/MM/YYYY');
    return (
        <div className='cms-treks-wrapper'>
            <div className='header'>
                <AutoComplete
                    value={query}
                    name="query"
                    label={texts['web_cms_search_a_trek']}
                    onChange={onChange}
                    onSelect={onSelect}
                    options={formatOptions(options)}
                    className={'search-box'}
                />
            </div>
            {trek && <div className='trek-details'>
                <div className='left-side'>
                    <Swiper
                        className='swiper-carousel'
                        navigation={{
                            nextEl: '.swiper-next-ele',
                            prevEl: '.swiper-prev-ele'
                        }}
                        onSwiper={(swiper) => swiperRef.current = swiper}
                        modules={[Pagination, Navigation]}
                        pagination={{ clickable: true }}
                    >
                        {trek.imgs.map((img, idx) => (
                            <SwiperSlide key={`trek_image_${idx}`} className={'swiper-slide'}>
                                <img className='trek-image' src={img} alt='/' />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <BsArrowLeftCircleFill className='swiper-prev-ele'/>
                    <BsArrowRightCircleFill className='swiper-next-ele'/>
                </div>
                <div className='middle-section'>
                    <FloatInput
                        label={'ID'}
                        value={trek._id}
                        className={'trek-field'}
                        disabled
                    />
                    <FloatInput
                        label={texts['describe_trek_placeholder_name']}
                        value={trek.name}
                        name={'name'}
                        className={'trek-field'}
                        disabled={!editable}
                        onChange={trekDetailsChangeHandler}
                    />
                    <FloatTextArea
                        label={texts['describe_trek_placeholder_description']}
                        value={trek.description}
                        name={'description'}
                        disabled={!editable}
                        onChange={trekDetailsChangeHandler}
                    />
                    {editable && <Button onClick={trekDetailsSaveHandler}>{texts['web_cms_buttons_save']}</Button>}
                </div>
                <div className="right-side">
                    <div className="trek-switch-wrapper caution">
                        <span className="trek-switch-text">{texts['web_cms_edit_details']}</span>
                        <Icon path={editable ? mdiLockOpenVariant:mdiLock} size={1} onClick={setEditable.bind(this, true)} />
                    </div>
                    <div className="trek-switch-wrapper">
                        <span className="trek-switch-text">{texts['describe_trek_accessible']}</span>
                        <Switch
                            disabled={!editable}
                            isToggled={trek.isAccessible}
                            rounded
                            name={'isAccessible'}
                            onToggle={trekDetailsChangeHandler}
                        />
                    </div>
                    <div className="trek-counters-wrapper">
                        <TreksCounter
                            value={trek.likes.length}
                            label={texts['web_cms_table_column_likes']}
                            disabled={trek.likes.length === 0}
                        />
                        <TreksCounter
                            value={trek.rate}
                            label={texts['web_cms_table_column_rate']}
                            disabled={true}
                        />
                        <TreksCounter
                            value={trek.raters?.length}
                            label={texts['web_cms_trek_raters']}
                            disabled={!trek.raters || trek.raters.length === 0}
                        />
                    </div>
                    <div className='posted-by'>
                        {texts['trek_posted_by']} <span className='author' onClick={goToAuthor}>{trek.author.displayName}</span> {texts['web_cms_posted_on']} {date}
                    </div>
                    <div className="comments-wrapper">
                        {trek.comments.map((comment, idx) => (
                            <Comment
                                key={`trek-comment-${comment._id}`}
                                {...comment}
                            />
                        ))}
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default Treks;