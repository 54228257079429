import Actions from "redux/actions";
import Store from "../redux";

class DeviceState {
    constructor() {
        const syntheticEvent = {
            target: {
                innerHeight: window.innerHeight,
                innerWidth: window.innerWidth
            }
        }
        this.resizeHandler(syntheticEvent);
        this.subscription = window.addEventListener('resize', this.resizeHandler);
    }

    resizeHandler(event) {
        const { innerWidth, innerHeight } = event.target;
        const res = {
            isMobile: false,
            isTablet: false,
            isDesktop: false,
            isDesktopLarge: false,
            isDesktopMax: false,
            notDesktop: false,
            notMobile: false,
        };
        if(innerWidth < 768) {
            res.isMobile = true;
            res.notDesktop = true;
        }
        if(innerWidth < 1200 && innerWidth >= 768){
            res.isTablet = true;
            res.notMobile = true;
            res.notDesktop = true;
        }
        if(innerWidth < 1500 && innerWidth >= 1200){
            res.isDesktop = true;
            res.notMobile = true;
        }
        if(innerWidth < 1920 && innerWidth >= 1500){
            res.isDesktopLarge = true;
            res.notMobile = true;
        }
        if(innerWidth >= 1920){
            res.isDesktopMax = true;
            res.notMobile = true;
        }
        Store.dispatch(Actions.setDeviceState(res));
    }
}

const deviceState = new DeviceState();
Object.freeze(deviceState);
export default deviceState;