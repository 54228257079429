import React from "react";
import "./Switch.scss";

const Switch = ({ 
        rounded = false, 
        isToggled, 
        onToggle = () => {}, 
        className = '', 
        colors = {}, 
        label,
        name,
        disabled = false,
        ...props 
    }) => {

    const roundedClass = rounded ? 'rounded':'';
    const railColor = isToggled ? colors?.rail?.active : colors?.rail?.inactive;
    const thumbColor = isToggled ? colors?.thumb?.active : colors?.thumb?.inactive;

    const onChange = (e) => {
        const { name, checked } = e.target;
        onToggle(name, checked);
    };
    

    return (
        <label className={`switch-wrapper ${className}`}>
            <input type="checkbox" checked={isToggled} onChange={onChange} name={name} disabled={disabled} />
            <span className={`switch-slider ${roundedClass}`} style={{backgroundColor: railColor}}>
                <span className="switch-thumb" style={{backgroundColor: thumbColor}} />
                {label && <div className='switch-label'>{label}</div>}
            </span>
        </label>
    );
};

export default Switch;
