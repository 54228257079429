import { dataReducers } from "../slices/data";
import { popupReducers } from '../slices/popups';
import { stateReducers } from '../slices/state';

let Reducers = {};

for (let reducer in dataReducers) {
    Reducers[reducer] = dataReducers[reducer];
}

for (let reducer in popupReducers) {
    Reducers[reducer] = popupReducers[reducer];
}

for (let reducer in stateReducers) {
    Reducers[reducer] = stateReducers[reducer];
}


export default Reducers;
