import Button from 'components/UI/Button';
import { CMS_SUBS } from 'constants/routes';
import useLanguage from 'hooks/useLanguage';
import useTitle from 'hooks/useTitle';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Actions from 'redux/actions';
import './index.scss';


const CMSHome = (props) => {
    useTitle(props);
    const texts = useLanguage();
    const userData = useSelector(store => store.userData);
    const dispatch = useDispatch();

    const onLogout = () => {
        localStorage.removeItem("@silentLoginToken");
        dispatch(Actions.setUserData(null));
    }
    

    return (
        <div className='cms-home-wrapper'>
            <div className='welcome-msg'>
                <div className='title'>
                    {process.env.REACT_APP_NAME}
                </div>
                {texts['web_cms_homepage_welcome_message']}
                {userData ? <div>{texts['web_cms_homepage_connected_as']?.replace('[_]', userData.displayName)}</div>:<NavLink 
                    to={CMS_SUBS.root.subs.login.path}
                    className={"login-btn"}
                >
                    {texts['web_cms_buttons_login']}
                </NavLink>}
                {userData && <Button onClick={onLogout} className={"logout-btn"}>{texts['web_cms_buttons_log_out']}</Button>}
            </div>
        </div>
    );
};

export default CMSHome;