import React, { useEffect } from 'react';

/* ---------- React-Router ---------- */
import { NavLink, Outlet }  from 'react-router-dom';
import { setFavIcon } from 'utilites';


/* ---------- Components ---------- */
import Header   from './components/header';

/* ---------- Assets ---------- */
import './index.scss';
import FavIcon from 'assets/favicons/cms.png';
import useLanguage from 'hooks/useLanguage';
import { useDispatch } from 'react-redux';
import Actions from 'redux/actions';
import popupTypes from 'constants/popup-types';


const CMS = () => {
    const texts = useLanguage();
    const dispatch = useDispatch();

    useEffect(() => {
        setFavIcon(FavIcon);
    }, []);

    const languageHandler = () => {
      dispatch(Actions.addPopup({
        type: popupTypes.CHANGE_LANGUAGE
      }));
    };
    
    return (
        <div className='cms-wrapper'>
            <Header/>
            <div className="content">
                <Outlet/>
            </div>
            <div className='cms-footer'>
                <NavLink to={"/"} className="back-to-site-link">{texts['web_cms_back_to_site']}</NavLink>
                <div className='change-language' onClick={languageHandler}>
                    {texts['account_settings_change_language']}
                </div>
            </div>
        </div>
    );
};

export default CMS;