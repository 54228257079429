import React, { useState, useRef }   from 'react';

/* ---------- Redux ---------- */
import { useDispatch, useSelector } from 'react-redux';
import Actions                      from 'redux/actions';

/* ---------- Functions ---------- */
import { deepCloneObjectsArray }    from 'utilites';

/* ---------- Assets ---------- */
import './index.scss';
import { AiOutlineUpload, AiOutlineDelete } from 'react-icons/ai';

/* ---------- Constants ---------- */
import * as NOTIFICATIONS   from 'constants/notification-types';

/* ---------- API ---------- */
import { removeCategoryImage, uploadCategoryImage } from 'lib/storage-api';

/* ---------- Components ---------- */
import TableRow from './table-row';
import Button   from 'components/UI/Button';
import useTitle from 'hooks/useTitle';
import useLanguage from 'hooks/useLanguage';

const initialFields = {
    id: "",
    title: {},
    order_num: "",
    icon: ""
}

const Categories = (props) => {
    useTitle(props);
    const texts = useLanguage();
    const imageRef = useRef();
    const dispatch = useDispatch();
    const parameters = useSelector(store => store.gd.parameters);
    const languages = useSelector(store => store.gd.translations);
    const sortedLanguages = Object.keys(languages).sort((curr, prev) => languages[curr].ORDER_NUM < languages[prev].ORDER_NUM ? -1:1);
    const categories = useSelector(store => store.gd.categories);
    const [fields, setFields] = useState({ title: {} });
    const [imagePreview, setImagePreview] = useState("");

    const loadImage = (file) => {
        if(file){
            const reader = new FileReader();

            reader.onload = (e) => {
                setImagePreview(e.target.result);
            }

            reader.readAsDataURL(file);
        }
    }  

    const onFieldChange = (e) => {
        const { value, name, type } = e.target;
        const updatedFields = {...fields};
        if(sortedLanguages.includes(name)){
            updatedFields.title[name] = value;
        } else {
            if(type === 'file'){
                loadImage(imageRef.current.files[0]);
                updatedFields[name] = imageRef.current.files[0];
            } else {
                updatedFields[name] = value;
            }
        }
        setFields(updatedFields);
    }

    const onAdd = async () => {
        let updatedCategories = [...categories];
        const downloadURL = await uploadCategoryImage(fields.title.en, fields.icon);
        const newCategory = {
            id: parameters.numOfCategories + 1,
            order_num: categories.length + 1,
            title: fields.title,
            icon: downloadURL
        }
        updatedCategories.push(newCategory);
        const updatedParameters = { ...parameters };
        updatedParameters.numOfCategories += 1;
        dispatch(Actions.setGeneralDeclaration({ categories: updatedCategories, parameters: updatedParameters }));
        setFields(initialFields);
        setImagePreview('');        
        dispatch(
            Actions.addNotification({
                title: "Success",
                text: `Category added successfully!`,
                status: NOTIFICATIONS.SUCCESS,
                duration: 3000,
            })
        );
    }
    
    const openFileBrowser = () => {
      imageRef.current.click();
    }
    
    const unselectImage = () => {
        const changeObj = {
            target: {
                name: 'icon',
                value: ""
            }
        }
        onFieldChange(changeObj);
        setImagePreview("");
    }

    const handleRemoveCategory = (id) => {
        const catToRemove = (categories.filter((cat) => cat.id === id))[0];
        let cats = [];
        for(const cat of categories) {
            if(cat.id !== id){
                cats.push(cat);
            }
        }
        removeCategoryImage(catToRemove.title.en);
        dispatch(Actions.setGeneralDeclaration({ categories: cats }));
        dispatch(
            Actions.addNotification({
                title: "Success",
                text: `Category removed successfully!`,
                status: NOTIFICATIONS.SUCCESS,
                duration: 3000,
            })
        );
    }
    
    const categoriesArr = deepCloneObjectsArray(categories);
    const sortedCategories = categoriesArr.sort((curr, prev) => curr.order_num < prev.order_num ? -1:1);
    return (
        <div className='categories-wrapper'>
            <div className='controls-wrapper'>
            </div>
            <table>
                <thead>
                    <tr>
                        <td className='number'>
                            {texts['web_cms_table_column_id']}
                        </td>
                        <td className='icon'>
                            {texts['web_cms_table_column_icon']}
                        </td>
                        <td className='icon'>
                            {texts['web_cms_table_column_map_pin']}
                        </td>
                        <td className='languages-wrapper'>
                            <div className='language-name'>{texts['web_cms_table_column_title']}</div>
                            <div className='languages-names'>
                                {sortedLanguages.map((code, idx) => (
                                    <div 
                                        key={`${languages[code].LANGUAGE}_${code}`}
                                        className={languages[code].DIRECTION}
                                    >{languages[code].LANGUAGE}</div>
                                ))}
                            </div>
                        </td>
                        <td className='number'>
                            {texts['web_cms_table_column_order_num']}
                        </td>
                        <td className='number'>
                            
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='number' />
                        <td className='icon'>
                            {imagePreview === '' ? (<>
                                <input 
                                    ref={imageRef}
                                    type={'file'} 
                                    name="icon" 
                                    className='img-input-hidden' 
                                    onChange={onFieldChange} 
                                    accept="image/png"
                                />
                                <AiOutlineUpload className='upload-icon' size={24} onClick={openFileBrowser}/>
                            </>):(<>
                                <img src={imagePreview} alt='/' className='thumbnail'/>
                                <AiOutlineDelete className='delete-icon' size={14} onClick={unselectImage}/>
                            </>
                            )}
                        </td>
                        <td className='icon'>
                            {imagePreview === '' ? (<>
                                <input 
                                    ref={imageRef}
                                    type={'file'} 
                                    name="mapPin" 
                                    className='img-input-hidden' 
                                    onChange={onFieldChange} 
                                    accept="image/png"
                                />
                                <AiOutlineUpload className='upload-icon' size={24} onClick={openFileBrowser}/>
                            </>):(<>
                                <img src={imagePreview} alt='/' className='thumbnail'/>
                                <AiOutlineDelete className='delete-icon' size={14} onClick={unselectImage}/>
                            </>
                            )}
                        </td>
                        <td className='languages-wrapper'>
                            <div className='languages-names'>
                                {sortedLanguages.map((lang, idx) => (
                                    <textarea 
                                        key={`add_${lang}_${idx}`}
                                        className={languages[lang].DIRECTION}  
                                        value={fields.title[lang] ? fields.title[lang]:""} 
                                        onChange={onFieldChange} 
                                        name={lang}
                                    />
                                ))}
                            </div>
                        </td>
                        <td className='number'>
                            <input type={"number"} value={fields.order_num} onChange={onFieldChange} name="order_num" />
                        </td>
                        <td>
                            <Button
                                onClick={onAdd}
                            >{texts['web_cms_buttons_add']}</Button>
                        </td>
                    </tr>
                    {sortedCategories.map((cat, idx) => (
                        <TableRow
                            key={`${cat.icon}_${idx}`}
                            data={cat}
                            onRemove={handleRemoveCategory}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Categories;