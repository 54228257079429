import React, { useState } from 'react';
import { capitalize } from 'utilites';
import './index.scss';

const availableTypes = [
    'number',
    'string',
    'array',
    'object'
];

const ObjectEditor = (props) => {
    const { payload = {} } = props;
    const { initialData = {}, onChange = () => {} } = payload;
    const [objectData, setObjectData] = useState(initialData);
    
    const getValueType = (value) => {
        if(Array.isArray(value)){
          return availableTypes[2];
        }
        return typeof value;
      };

    const getSelectDropdown = (value, key) => {
        return <select defaultValue={getValueType(value)} >
            {availableTypes.map((type, index) => (
                <option value={type} key={`${key}-availableTypes-${index}`}>{capitalize(type)}</option>
            ))}
        </select>
    };

    const getTypeBrackets = (value) => {
        if(Array.isArray(value)){
            return ['[', ']'];
        }
        if(typeof value === 'object') {
            return ['{', '}']
        }
        return ['',''];
    };
    

    const getObjectFields = (key, value) => {
        const brackets = getTypeBrackets(value);
        if(typeof value === 'object') {
            return (
            <div className='object-key-wrapper object' style={{ marginLeft: 20,}}>
                <div className='object-key-left'>
                    {getSelectDropdown(value, `object-keys-${key}}`)}
                    <span className='object-key'>{key}:</span>
                    {brackets[0]}
                </div>
                {Object.keys(value).map((key, idx) => (
                    <div className='object-key-wrapper'>
                        <div className='object-key-right'>
                            {getObjectFields(key, value[key])}
                        </div>
                    </div>
                ))}
                {brackets[1]},
            </div>
        )}
        return getItem(key, value);
    };

    

    const getItem = (key, value) => {
      return <div className='object-key-wrapper' style={{ marginLeft: 20,}}>
                <div className='object-key-left'>
                    {getSelectDropdown(value, `object-keys-${key}}`)}
                    <span className='object-key'>{key}:</span>
                    
                </div>
                <div className='object-key-right'>
                    {value},
                </div>
            </div>
    };
    

    return (
        <div className='object-editor-popup'>
            <prev>{'{'}</prev>
            {Object.keys(objectData).map((key, idx) => getObjectFields(key, objectData[key]))}
            <prev>{"}"}</prev>
        </div>
    );
};

export default ObjectEditor;