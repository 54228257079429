import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { BiErrorCircle } from "react-icons/bi";
import "./index.scss";
import {
    AiOutlineCheckCircle,
    AiOutlineInfoCircle,
    AiOutlineWarning,
} from "react-icons/ai";
import { IoMdClose } from 'react-icons/io';
import Actions from "../../../redux/actions";
import Timer from "../../../utilites/Timer";
import * as NOTIFICATIONS from '../../../constants/notification-types';
import useLanguage from "hooks/useLanguage";

const FADE_IN = 100;

const Notification = (props) => {
    const { payload, id } = props;
    const { 
        title, 
        text, 
        duration = 5000,
        status = NOTIFICATIONS.SUCCESS,
        onClick
    } = payload;

    const texts = useLanguage();
    const timerRef = useRef();
    const dispatch = useDispatch();
    const [animate, setAnimate] = useState(false);


    const getNotificationData = () => {
        const TYPES = {
            [NOTIFICATIONS.WARNING]: {
                color: "#fa9f47",
                icon: <AiOutlineWarning />,
                title: texts['web_cms_notification_warning_title']
            },
            [NOTIFICATIONS.ERROR]: {
                color: "#d64242",
                icon: <BiErrorCircle />,
                title: texts['web_cms_notification_error_title']
            },
            [NOTIFICATIONS.INFO]: {
                color: "#0090e0",
                icon: <AiOutlineInfoCircle />,
                title: texts['web_cms_notification_info_title']
            },
            [NOTIFICATIONS.SUCCESS]: {
                color: "#34c240",
                icon: <AiOutlineCheckCircle />,
                title: texts['web_cms_notification_success_title']
            },
        };
        return TYPES[status];
    }

    const handleStartTimer = () => {
        if(!timerRef.current){
            timerRef.current = new Timer(onCloseNotification, duration);
        } else {
            timerRef.current.start();
        }
    };

    const handlePauseTimer = () => {
        timerRef.current.pause();
    };

    const onCloseNotification = () => {
        handlePauseTimer();
        setAnimate(false);
        setTimeout(() => {
            dispatch(Actions.removeNotification(id));
        }, 800);
    };

    useEffect(() => {
        handleStartTimer();
        setTimeout(() => {
            setAnimate(true);
        }, FADE_IN);
    }, []);

    const typeData = getNotificationData();
    const slideIn = animate ? "slide" : "";
    return (
        <div
            className={`notification-wrapper ${slideIn}`}
            style={{ backgroundColor: typeData.color, cursor: typeof onClick === 'function' ? 'pointer':'default' }}
            onMouseEnter={handlePauseTimer}
            onMouseLeave={handleStartTimer}
            onClick={onClick}
        >
            <div className="icon-wrapper">{typeData.icon}</div>
            <div className="title-text-wrapper">
                <div className="notification-title">{title ? title:typeData.title}</div>
                <div className="notification-text">{text}</div>
            </div>
            <div className="close-notification">
                <IoMdClose size={22} onClick={onCloseNotification}/>
            </div>
        </div>
    );
};

export default Notification;
