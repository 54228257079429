import React from 'react';
import './index.scss';


const BasicPopup = (props) => {
    return (
        <div className='basic-popup-wrapper'>
            
        </div>
    );
};

export default BasicPopup;