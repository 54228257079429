import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux/actions';
import { Validate } from 'utilites';


const useInput = (isPopup = false, initialState = {}, rules = {}) => {
    const dispatch = useDispatch();
    const [fields, setFields] = useState(JSON.parse(JSON.stringify(initialState)));

    const onChange = (e) => {
        const { name, value } = e.target;
        const updatedFields = { ...fields };
        if(Object.keys(rules).length !== 0){
            if(!updatedFields[name]) {
                updatedFields[name] = {
                    value: '',
                    errMsg: '',
                    isValid: false
                };
            }
            const validationObj = Validate(value, rules[name]);
            if(name.includes('.')){
                const parts = name.split('.');
                if(!updatedFields[parts[0]]) {
                    updatedFields[parts[0]] =  {
                        value: '',
                        errMsg: '',
                        isValid: false
                    };
                }
                updatedFields[parts[0]][parts[1]].value = value;
                updatedFields[parts[0]][parts[1]].errMsg = validationObj?.msg;
                updatedFields[parts[0]][parts[1]].isValid = validationObj?.valid;
            } else {
                updatedFields[name].value = value;
                updatedFields[name].errMsg = validationObj?.msg;
                updatedFields[name].isValid = validationObj?.valid;
            }
        } else {
            if(!updatedFields[name]) {
                updatedFields[name] = {};
            }
            if(name.includes('.')){
                const parts = name.split('.');
                if(!updatedFields[parts[0]]) {
                    updatedFields[parts[0]] =  {};
                }
                updatedFields[parts[0]][parts[1]] = value;
            } else {
                updatedFields[name] = value;
            }
        }
        setFields(updatedFields);
        if(isPopup) {
            dispatch(Actions.setUnsavedPopup());
        }
    };

    const validateForm = () => {
        let isValid = true;
        const updatedFields = { ...fields };
        for(const field in updatedFields) {
            const validationObj = Validate(updatedFields[field].value, rules[field]);
            updatedFields[field].isValid = validationObj.valid;
            updatedFields[field].errMsg = validationObj.msg;
            if(!validationObj.valid){
                isValid = false;
            }
        }
        setFields(updatedFields);
        return isValid;
    };
    

    const resetForm = () => {
        const updatedForm = { ...fields };
        for(const field in fields) {
            fields[field].value = '';
            fields[field].isValid = false;
            fields[field].errMsg = '';
        }
        setFields(updatedForm);
    };
    
    return [fields, onChange, resetForm, validateForm];
};

export default useInput;