import React, { useEffect, useState, useRef } from 'react';
import FloatInput from '../FloatInput';
import './index.scss';


const AutoComplete = (props) => {
    const [showOptions, setShowOptions] = useState(false);
    
    const {
        value,
        onChange = () => {},
        onSelect = () => {},
        options = [],
        label = '',
        disabled = false,
        className = '',
        name
    } = props;

    const onFocus = () => {
        setShowOptions(true);
    };

    const onBlur = () => {
        setShowOptions(false);
    };

    const showClass = showOptions ? "show":"";
    return (
        <div className={`auto-complete-wrapper ${className}`}>
            <FloatInput
                value={value}
                name={name}
                label={label}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                className={'auto-complete-input'}
            />
            {options?.length > 0 && (
            <div className={`auto-complete-options-wrapper ${showClass}`}>
                {options?.map((option,idx) => (
                    <React.Fragment key={`drop-down-option-${option._id}`}>
                    {idx !== 0 && <div className='divider'/>}
                    <Option
                        onClick={onSelect}
                        id={option._id}
                        text={option.text}
                        value={value}
                    />
                    </React.Fragment>
                ))}
            </div>)}
        </div>
    );
};

export default AutoComplete;


const Option = (props) => {
    const { 
        onClick = () => {},
        id,
        text = '',
        rightSide,
        value 
    } = props;
    const startOfQuery = text.toLowerCase().indexOf(value.toLowerCase());
    const start = text.substring(0, startOfQuery);
    let bold = [];
    for(let i = startOfQuery, j = 0; j < value.length; i++, j++){
        if(text.toLowerCase().charAt(i) === value.toLowerCase().charAt(j)){
            bold.push(text.charAt(i));
        } else {
            break;
        }
    }
    bold = bold.join("");
    const end = text.substring(startOfQuery + bold.length);
    return (
        <div className='auto-complete-option-wrapper' onClick={onClick.bind(this, id)}>
            <div className='auto-complete-option-text'>
                {start}
                <span className='auto-complete-option-bold'>{bold}</span>
                {end}
            </div>
            {rightSide}
        </div>
    )
}
