import { createSlice } from "@reduxjs/toolkit";


let Slices = [];
let stateReducers = {};
let stateActions = {};

const requestingSlice = createSlice({
  name: "requestingState",
  initialState: false,
  reducers: {
    requestStarted: (state, action) => true,
    requestEnded: (state, action) => false,
  },
});

// Action creators are generated for each case reducer function
Slices.push(requestingSlice);

const deviceState = createSlice({
  name: "deviceState",
  initialState: {},
  reducers: {
    setDeviceState: (state, action) => action.payload,
  },
});

// Action creators are generated for each case reducer function
Slices.push(deviceState);



const cmsInfo = createSlice({
  name: "cms",
  initialState: {},
  reducers: {
    setAlertLanguage: (state, action) => {
      return { ...state, selectedAlertLanguage: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
Slices.push(cmsInfo);


const unsavedPopup = createSlice({
  name: "unsavedPopup",
  initialState: false,
  reducers: {
    setUnsavedPopup: () => {
      return true;
    },
    resetUnsavedPopup: () => {
      return false;
    }
  },
  extraReducers: {
    'popupsArray/removePopup': () => {
      return false;
    }
  }
});

// Action creators are generated for each case reducer function
Slices.push(unsavedPopup);


for (const Slice of Slices) {
    stateActions = { ...stateActions, ...Slice.actions };
    let reducer = { [Slice.name]: Slice.reducer };
    stateReducers = { ...stateReducers, ...reducer };
  }
  
  export { stateActions };
  export { stateReducers };