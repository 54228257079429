import Api from 'api/requests';
import AutoComplete from 'components/UI/AutoComplete';
import FloatInput from 'components/UI/FloatInput';
import FloatTextArea from 'components/UI/FloatTextArea';
import Switch from 'components/UI/Switch';
import Icon from '@mdi/react';
import { CMS_SUBS } from 'constants/routes';
import useLanguage from 'hooks/useLanguage';
import useTitle from 'hooks/useTitle';
import TreksCounter from 'pages/CMS/components/treks-counter';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './index.scss';
import { mdiCheckDecagram, mdiLock, mdiLockOpenVariant } from '@mdi/js';
import { useDispatch } from 'react-redux';
import Actions from 'redux/actions';
import popupTypes from 'constants/popup-types';
import Button from 'components/UI/Button';


const ContentUsers = (props) => {
    const texts = useLanguage();
    const timerRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [user, setUser] = useState(null);
    useTitle(props, user?.displayName);
    const [options, setOptions] = useState(null);
    const [query, setQuery] = useState("");
    const [editable, setEditable] = useState(false);
    
    const onChange = (name, value) => {
        setQuery(value);

        if(value.length > 3) {
            const fetchOptions = () => {
                const onSuccess = (response) => {
                    setOptions(response);
                }

                const props = {
                    onSuccess,
                    payload: {
                        query: value
                    }
                }; 

                Api.getUsersByQuery(props);
                
            };
            
            debounce(fetchOptions, 550);
        } else {
            setOptions(null);
        }
    };

    const debounce = (callback, timeout) => {
        if(timerRef.current){
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(callback, timeout);
    };
    
    const onSelect = (id) => {
        navigate(`${CMS_SUBS.content.subs.users.path}/${id}`);
    };
    
    useEffect(() => {
        if(location.pathname === CMS_SUBS.content.subs.users.path){
            setUser(null);
        }
    }, [location.pathname]);
    
    useEffect(() => {
        if ((params.userId && !user) || params.userId !== user?._id) {
            const onSuccess = (response) => {
                setUser(response);
            }
            const props = {
                onSuccess,
                payload: {
                    id: params.userId
                }
            }
            Api.getUserById(props);
            setEditable(false);
        }
    }, [params, user]);

    const formatOptions = (options) => {
        const res = [];
        for(const i in options) {
            res.push({
                text: options[i].displayName,
                _id: options[i]._id,
            });
        }
        return res;
    };

    const showUserSavedTreks = () => {
        dispatch(Actions.addPopup({
            type: popupTypes.TREKS_TABLE,
            payload: {
                userId: user._id,
                headlineText: 'web_cms_saved_treks_headline',
                displayName: user.displayName,
                method: 'getTreksSavedByUser',
                columns: [
                    {
                        key: 'name',
                        textKey: 'web_cms_table_column_trek_name',
                        onClick: (trek) => navigate(CMS_SUBS.content.subs.treks.path + '/' + trek._id),
                        data: (data) => data
                    },
                    {
                        key: 'author',
                        textKey: 'web_cms_table_column_author',
                        data: (data) => data.displayName,
                        onClick: (trek) => navigate(CMS_SUBS.content.subs.users.path + '/' + trek.author._id) 
                    },
                    {
                        key: 'postedOn',
                        textKey: 'web_cms_table_column_posted_on',
                        data: (data) => {
                            const postedOn = new Date(data);
                            return `${postedOn.getDate()}/${postedOn.getMonth() + 1}/${postedOn.getFullYear()}`;
                        }
                    },
                ]
            }
        }))
    };

    const showUserTreks = () => {
        dispatch(Actions.addPopup({
            type: popupTypes.TREKS_TABLE,
            payload: {
                userId: user._id,
                headlineText: 'web_cms_user_treks_headline',
                displayName: user.displayName,
                method: 'getTreksByUser',
                columns: [
                    {
                        key: 'name',
                        textKey: 'web_cms_table_column_trek_name',
                        onClick: (trek) => {
                            navigate(CMS_SUBS.content.subs.treks.path + '/' + trek._id);
                        },
                        data: (data) => data
                    },
                    {
                        key: 'postedOn',
                        textKey: 'web_cms_table_column_posted_on',
                        data: (data) => {
                            const postedOn = new Date(data);
                            return `${postedOn.getDate()}/${postedOn.getMonth() + 1}/${postedOn.getFullYear()}`;
                        }
                    },
                    {
                        key: 'rate',
                        textKey: 'web_cms_table_column_rate',
                        data: (data) => {
                            return data;
                        }
                    },
                    {
                        key:'likes',
                        textKey: 'web_cms_table_column_likes',
                        data: (data) => {
                            return data.length;
                        }
                    }
                ]
            }
        }))
    };

    const userDetailsChangeHandler = (name, value) => {
        const temp = JSON.parse(JSON.stringify(user));
        temp[name] = value;
        setUser(temp);
    };
    
    const userDetailsSaveHandler = () => {
        const onSuccess = (data) => {
            setUser(data);
            setEditable(false);
        };
        const props = {
            onSuccess,
            payload: {
                _id: user._id,
                accountVerified: user.accountVerified,
                emailVerified: user.emailVerified,
                bio: user.bio,
                displayName: user.displayName,
                email: user.email,
                isAdmin: user.isAdmin
            }
        };
        Api.updateUserDetails(props);
    };
    
    
    const dob = user?.dob ? new Date(parseInt(user.dob)):undefined;
    const dateOfBirthFormat = dob ? `${dob.getDate()}/${dob.getMonth()+1}/${dob.getFullYear()}`:undefined;
    return (
        <div className='content-users-wrapper'>
             <div className='header'>
                <AutoComplete
                    value={query}
                    name="query"
                    label={texts['web_cms_search_a_user']}
                    onChange={onChange}
                    onSelect={onSelect}
                    options={formatOptions(options)}
                    className={'search-box'}
                />
            </div>
            {user && <div className='user-details'>
                <div className='left-side'>
                    <img src={user.photoUrl} alt='/' className='user-image' />
                </div>
                <div className='right-side'>
                    <div className='right-side-top'>
                        <div className='right-side-top-left'>
                            <FloatInput
                                label={'ID'}
                                value={user._id}
                                className='user-field'
                                disabled
                            />
                            <FloatInput
                                label={texts['field_full_name']}
                                value={user.displayName}
                                name={'displayName'}
                                disabled={!editable}
                                onChange={userDetailsChangeHandler}
                                className='user-field'
                            />
                            <FloatInput
                                label={texts['field_email']}
                                value={user.email}
                                name={'email'}
                                disabled={!editable}
                                onChange={userDetailsChangeHandler}
                                className='user-field'
                            />
                            <FloatTextArea
                                label={texts['field_bio']}
                                value={user.bio}
                                name={'bio'}
                                disabled={!editable}
                                onChange={userDetailsChangeHandler}
                            />
                        </div>
                        <div className='right-side-top-right'>
                            <div className='user-switch-wrapper caution'>
                                <span className='user-switch-text'>{texts['web_cms_edit_details']}</span>
                                <Icon path={editable ? mdiLockOpenVariant:mdiLock} size={1} onClick={() => setEditable(true)}/>
                            </div>
                            <div className='user-switch-wrapper caution'>
                                <span className='user-switch-text'>{texts['web_cms_user_banned']}</span>
                                <Switch
                                    disabled={!editable}
                                    isToggled={false}
                                    rounded
                                    name={'disabled'}
                                    onToggle={userDetailsChangeHandler}
                                />
                            </div>
                            <div className='user-switch-wrapper'>
                                <span className='user-switch-text'>{texts['web_cms_users_account_verified']}<Icon path={mdiCheckDecagram} className={'verified-badge'} /> : </span>
                                <Switch
                                    disabled={!editable}
                                    isToggled={user.accountVerified}
                                    rounded
                                    name={'accountVerified'}
                                    onToggle={userDetailsChangeHandler}
                                />
                            </div>
                            <div className='user-switch-wrapper'>
                                <span className='user-switch-text'>{texts['web_cms_users_email_verified']}:</span>
                                <Switch
                                    disabled={!editable}
                                    isToggled={user.emailVerified}
                                    rounded
                                    name={'emailVerified'}
                                    onToggle={userDetailsChangeHandler}
                                />
                            </div>
                            <div className='user-switch-wrapper'>
                                <span className='user-switch-text'>{texts['web_cms_users_is_admin']}:</span>
                                <Switch
                                    disabled={!editable}
                                    isToggled={user.isAdmin}
                                    rounded
                                    name={'isAdmin'}
                                    onToggle={userDetailsChangeHandler}
                                />
                            </div>
                            <div className='user-switch-wrapper'>
                                <span className='user-switch-text'>{texts['field_dob']}</span>
                                <span className='user-switch-text value'>{dateOfBirthFormat}</span>
                            </div>
                            <div className='user-counters-wrapper'>
                                <TreksCounter
                                    value={user.treks}
                                    label={texts['profile_treks_counter']}
                                    onClick={user.treks > 0 ? showUserTreks:undefined}
                                    disabled={user.treks === 0}
                                />
                                <TreksCounter
                                    value={user.treksDone?.length}
                                    label={texts['profile_treks_done']}
                                    onClick={() => {}}
                                    disabled={user.treksDone?.length === 0}
                                />
                                <TreksCounter
                                    value={user.savedTreks?.length}
                                    label={texts['drawer_saved_trek']}
                                    onClick={user.savedTreks?.length > 0 ? showUserSavedTreks:undefined}
                                    disabled={user.savedTreks?.length === 0}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='right-side-bottom'>
                        {editable && <Button onClick={userDetailsSaveHandler}>{texts['web_cms_buttons_save']}</Button>}
                    </div>
                </div>
            </div>}
            
        </div>
    );
};

export default ContentUsers;