import { createSlice } from '@reduxjs/toolkit';
import { deepCloneObjectsArray, reOrderArray } from '../../../utilites';

let Slices = [];
let dataActions = {};
let dataReducers = {};

/* --------- Slices --------- */
const generalDeclarationSlice = createSlice({
    name: 'gd',
    initialState: null,
    reducers: {
        setGeneralDeclaration: (state, action) => {
            return {...state, ...action.payload }
        },
        setTranslation: (state, action) => {
            const { translations } = state;
            const { key, value } = action.payload;
            
            for(const language in translations){
                if(!value){
                    delete translations[language][key];
                } else {
                    translations[language][key] = value[language];
                }
            }
        },
        setParameter: (state, action) => {
            const { parameters } = state;
            const { key, value } = action.payload;
            if(!value){
                delete parameters[key];
            } else {
                parameters[key] = value;
            }
        },
        setAlert: (state, action) => {
            const { alerts } = state;
            const { _id, data } = action.payload;
            const filteredAlert = alerts.filter((alert) => alert._id === _id);
            if(filteredAlert.length !== 1){
                alerts.push(data);
            } else {
                if(!data){
                    const newState = alerts.filter(alert => alert._id !== _id);
                    return { ...state, alerts: newState };
                } else {
                    alerts[alerts.indexOf(filteredAlert[0])] = data;
                }
            }
        },
        editCategory: (state, action) => {
            let updatedCategories = deepCloneObjectsArray(state.categories);
            updatedCategories = updatedCategories.map((cat) => {
                if(cat.id === action.payload.id) {
                    if(action.payload.newIcon){
                        cat.icon = action.payload.newIcon;
                    } else {
                        cat = action.payload;
                    }
                } 
                return cat;
            });
            return { ...state, categories: updatedCategories };
        },
        reorderCategories: (state, action) => {
            let updatedCategories = deepCloneObjectsArray(state.categories);
            const previousPlace = (updatedCategories.filter((cat) => cat.id === action.payload.id))[0].order_num;
            updatedCategories = reOrderArray(updatedCategories, previousPlace, action.payload.order_num, 'order_num')
            return { ...state, categories: updatedCategories };
        }
    }
})

Slices.push(generalDeclarationSlice);

const userSlice = createSlice({
    name: 'userData',
    initialState: null,
    reducers: {
        setUserData: (state, action) => action.payload,
    }
})

Slices.push(userSlice);

const accessToken = createSlice({
    name: 'accessToken',
    initialState: null,
    reducers: {
        setAccessToken: (state, action) => action.payload,
    }
})

Slices.push(accessToken);

const localStorage = createSlice({
    name: 'localStorage',
    initialState: null,
    reducers: {
        setLocalStorage: (state, action) => ({ ...state, ...action.payload }),
    }
})

Slices.push(localStorage);

/* ----- End of Slices ----- */

for(const Slice of Slices) {
    dataActions = { ...dataActions, ...Slice.actions };
    const reducer = { [Slice.name]: Slice.reducer };
    dataReducers = { ...dataReducers, ...reducer };
}

export { dataActions, dataReducers };