const Section = (props) => {
    const { className = '', id, children } = props;
    return (
        <div id={id} className={className}>
            {children}
        </div>
    );
};

export default Section;
