import React    from 'react';

/* ---------- Functions ---------- */
import { capitalize }   from 'utilites';

/* ---------- React-Router ---------- */
import { NavLink }  from 'react-router-dom';

/* ---------- Assets ---------- */
import './index.scss';
import useLanguage from 'hooks/useLanguage';


const DropdownMenu = (props) => {
    const texts = useLanguage();
    const { items, text } = props;
    return (
        <div className='dropdown-menu-wrapper'>
            <span className='visible'>{text}</span>
            <div className='items-wrapper'>
                {items && Object.keys(items).map((item, idx) => {
                    if(items[item].hide){
                        return;
                    }
                    return (<NavLink 
                        key={`${text}_dropdown_item_${idx}`}
                        to={items[item].path} 
                        className="dropdown-item"
                    >
                        {texts[`web_cms_header_dropdown_${item}`]}
                    </NavLink>)
                })}
            </div>
        </div>
    );
};

export default DropdownMenu;