import "./Footer.scss";
/* ---------- Constants ---------- */
import * as ROUTES  from 'constants/routes';

import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { CMS_SUBS } from "constants/routes";
import useLanguage from "hooks/useLanguage";
const Footer = () => {
    const texts = useLanguage();
    const userData = useSelector((state) => state.userData);

    const text = userData ? "web_footer_user_logged":'web_footer_user_not_logged';
    return (
        <div className={'footer-wrapper'}>
            <h1 className={'logo'}>{texts['app_name']}</h1>
            <span>
                <NavLink to={ROUTES.CMS}>{texts[text]}</NavLink>
            </span>
        </div>
    );
};

export default Footer;
