import React, { useEffect }    from "react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import "swiper/css/effect-cards";

/* ---------- React-Router ---------- */
import { Navigate, Route, Routes }  from "react-router-dom";
import PrivateRoute                 from "router/PrivateRoute";

/* ---------- Redux ---------- */
import { useDispatch, useSelector }  from "react-redux";

/* ---------- Contexts ---------- */
import { IconContext }  from "react-icons";

/* ---------- Constants ---------- */
import * as ROUTES  from './constants/routes';

/* ---------- Components ---------- */
import Notifications    from "./components/Notifications";
import Popups           from "./popups";

/* ---------- Screens ---------- */
import CMS          from "./pages/CMS";
import Home         from "./pages/Home";
import privateRoutes from "constants/private-routes";
import Splash from "pages/Splash";
import { CMSSubsTitles, CMSTitles } from "constants/site-titles";
import Actions from "redux/actions";

function App() {
    const dispatch = useDispatch();
    const popups = useSelector(store => store.popupsArray);
    const notifications = useSelector(store => store.notifications);

    useEffect(() => {
        const language = localStorage.getItem('@lang');
        dispatch(Actions.setLocalStorage({ language: language ?? 'en' }));
        if(!language){
            localStorage.setItem('@lang', 'en');
        }
    }, []);
    
    return (
        <IconContext.Provider value={{ className: "react-icons" }}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path={ROUTES.CMS} element={<CMS />}>
                    {Object.keys(ROUTES.CMS_SUBS).map((sub, idx) => {
                        const path = ROUTES.CMS_SUBS[sub].path;
                        const isProtected = privateRoutes.includes(path);
                        const Element = ROUTES.CMS_SUBS[sub].element;
                        const element = isProtected ? <PrivateRoute/>:<Element title={CMSSubsTitles[sub]}/>;
                        return (
                            <Route 
                                key={`CMS_${sub}_route_${idx}`}
                                path={path}
                                element={element}
                            >
                                {ROUTES.CMS_SUBS[sub].subs && Object.keys(ROUTES.CMS_SUBS[sub].subs).map((route) => { 
                                    const path = ROUTES.CMS_SUBS[sub].subs[route].path;
                                    const isProtected = privateRoutes.includes(path);
                                    const Element = ROUTES.CMS_SUBS[sub].subs[route].element;
                                    const element = isProtected ? <PrivateRoute/>:<Element title={CMSTitles(route, sub)}/>;
                                    return (
                                        <Route 
                                            key={`CMS_${sub}_route_${route}`}
                                            path={path}
                                            element={element}
                                        />
                                    );
                                })}
                            </Route>
                        );
                    })}
                </Route>
            </Routes>
            {popups.length > 0 && <Popups/>}
            {notifications.length > 0 && <Notifications/>}
            <Splash/>
        </IconContext.Provider>
    );
}

export default App;
