import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


const useLanguage = () => {
    const language = useSelector(store => store.localStorage?.language) || 'en';
    const translations = useSelector(store => store.gd?.translations);
    const DIRECTION = translations ? translations[language || 'en'].DIRECTION:'ltr';
    const [direction, setDirection] = useState(DIRECTION)

    useEffect(() => {
        if(translations){
            setDirection(translations[language || 'en'].DIRECTION);
        }
    }, [language, translations])

    useEffect(() => {
        const body = document.body;
        body.classList.remove('ltr');
        body.classList.remove('rtl');
        body.classList.add(direction);
    }, [direction])


    return translations ? translations[language]:{};
};

export default useLanguage;