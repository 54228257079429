import axios from 'axios';
import store from '../redux';
import Actions from 'redux/actions';
import errorTypes from 'constants/error-types';
import Api from './requests';

class ApiManager {

    #api = false;

    constructor() {
        this.#api = {
            https: true,
            baseUrl: process.env.REACT_APP_API_BASE_URL,
            parts:{
                path: 'api',
                version: process.env.REACT_APP_API_VERSION
            },
        }
    }

    #buildBaseUrl = (useGetUrl = false) => {

        let path = '/';
        for(let part in this.#api.parts) {
            let item = this.#api.parts[part];
            if(item !== '') {
                path = path + item + '/';
            }
        }
        return this.#api.baseUrl + path;
    };

    #call = (settings, onSuccess, onFailure, onError, callback) => {
        return axios(settings)
            .then((response) => {
                store.dispatch(Actions.requestEnded());
                if (typeof callback === "function") {
                    callback(response);
                }
                if(response.status === 200){
                    if (response.data?.status) {
                    onSuccess ? onSuccess(response.data.data) : this.#onSuccess();
                    return response.data;
                    } else {
                        if(response.data.err === errorTypes.TOKEN_EXPIRED){
                            const token = localStorage.getItem('@silentLoginToken');
                            if(token){
                                const onSuccesCB = (response) => {
                                    const accessToken = store.getState().accessToken;
                                    settings.headers.Authorization = 'Bearer ' + accessToken;
                                    this.#call(settings, onSuccess, onFailure, onError, callback);
                                }
                                const props = {
                                    onSuccess: onSuccesCB,
                                    payload: {
                                        token: localStorage.getItem('@silentLoginToken')
                                    }
                                }

                                Api.refreshToken(props);
                                return;
                            }
                        } 
                        onError ? onError(response.data.err) : this.#onError();
                    }
                } else {
                    onFailure
                    ? onFailure(response.message)
                    : this.#onFailure(response.message);
                }
            })
            .catch((error) => {
            store.dispatch(Actions.requestEnded());
            console.log("catch error:", error);
            this.#handleServerError(error);
        });
    };

    #generateRequest = (request, params = {}, method = 'post', timeout = false) => {
        let requestUrl = request;
        let data       = {};
        let settings   = {};
        
        //build form data
        if(!(Object.entries(params).length === 0 && params.constructor === Object)) {
            data = params;
        }

        settings.method = method;
        settings.url    = requestUrl;
        settings.timeout = timeout ? timeout : 1000 * 60 * 2;
        // settings.withCredentials = true;

        method === 'post' ? settings.data = data : settings.params = params;

        store.dispatch(Actions.requestStarted());

        return settings;
    };

    #onSuccess = () => {
        console.log('successful');
    };

    #onFailure = (response) => { // add modal with error.message
        const text = response?.err?.content !== undefined ? response.err.content : 'תקלת שרת, אנא נסה שנית מאוחר יותר';
        console.log(text);
    };
    #onError = (response) => { // add modal with error.message
        const text = response?.err?.content !== undefined ? response.err.content : 'תקלת שרת, אנא נסה שנית מאוחר יותר';
        console.log(text);
    };
    
    #handleServerError = (error) => { // add modal with error.message
        const text = error.message ? error.message : 'תקלת שרת, אנא נסה שנית מאוחר יותר';
        console.log(text);
    };

    _execute(props, methodName, onSuccess = false, onFailure = false, onError = false) {
        let request, method;

        method = 'post';
        request = this.#buildBaseUrl() + methodName;

        //override if props sent
        let override_path   = props?.config?.path !== undefined;
        let override_method = props?.config?.method !== undefined;
        if(override_method) { method  = props.config.method }
        if(override_path) { request = props.config.path + methodName }
        const headers = props?.config?.headers ? props.config.headers : undefined;
        const block = props?.config?.block !== undefined;
        const settings = this.#generateRequest(request, props.payload, method);
        const accessToken = store.getState().accessToken;
        settings.headers = { 
            ...headers,
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': `application/json`
        };

        if (!block)
            return this.#call(settings, onSuccess, onFailure, onError, props.callback);
            
    }
}

export default ApiManager;