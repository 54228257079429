const popupTypes = {
    BASIC: "BASIC",
    TWO_OPTION: "TWO_OPTION",
    LANGUAGES: "LANGUAGES",
    PARAMETERS: "PARAMETERS",
    ADD_ALERT: "ADD_ALERT",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    OBJECT_EDITOR: "OBJECT_EDITOR",
    TREKS_TABLE: "TREKS_TABLE",
}

export default popupTypes;