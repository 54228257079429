import React, { useEffect, useState } from "react";
import "./Home.scss";

import Header from "../components/UI/Header";
import Section from "../components/UI/Section";
import SplashImage from "../components/Home/Splash/SplashImage";
import Footer from "../components/UI/Footer";
import FavIcon from 'assets/favicons/normal.png';
import { ReactComponent as SocialIcon } from 'assets/features-icons/social.svg';
import { ReactComponent as CampingIcon } from 'assets/features-icons/camping.svg';
import { ReactComponent as WorldwideIcon } from 'assets/features-icons/worldwide.svg';
import ContactImg from 'assets/contact-us.jpg';
import { generateUniqueKey, setFavIcon, vw, vwToPixel } from "utilites";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay, EffectCards } from 'swiper';
import FloatInput from "components/UI/FloatInput";
import FloatTextArea from "components/UI/FloatTextArea";
import Button from "components/UI/Button";
import Api from 'api/requests';
import { useSelector } from "react-redux";
import useLanguage from "hooks/useLanguage";
import useInput from "hooks/useInput";

const imagesArray = [
    "https://firebasestorage.googleapis.com/v0/b/trekit-ab.appspot.com/o/web-statics%2Fslider%2Fslider-image-1.png?alt=media&token=6b69dff3-6498-4f32-8847-c66474d3cfd4",
    "https://firebasestorage.googleapis.com/v0/b/trekit-ab.appspot.com/o/web-statics%2Fslider%2Fslider-image-2.png?alt=media&token=eeabc6e2-34b4-4ee9-9bbe-3365685f4588",
    "https://firebasestorage.googleapis.com/v0/b/trekit-ab.appspot.com/o/web-statics%2Fslider%2Fslider-image-3.png?alt=media&token=07846d6f-10ed-4086-a333-bc74020cd2fc",
];

const features = [
    {
        id: generateUniqueKey(3),
        title: 'web_features_first_title',
        description: 'web_features_first_description',
        image: SocialIcon,
    },
    {
        id: generateUniqueKey(3),
        title: 'web_features_second_title',
        description: 'web_features_second_description',
        image: WorldwideIcon,
    },
    {
        id: generateUniqueKey(3),
        title: 'web_features_third_title',
        description: 'web_features_third_description',
        image: CampingIcon,
    },
];

const Home = () => {
    const deviceState = useSelector(store => store.deviceState);
    const formRules = {
        fullname: ['full_name','required'],
        email: ['email', 'required'],
        message: ['required']
    };
    const initialForm = {
        fullname: {},
        email: {},
        message: {}
    };
    const [form, onFormChange, resetForm, validateForm] = useInput(false, initialForm, formRules);
    const [firstTry, setFirstTry] = useState(true);
    const texts = useLanguage();

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_NAME;
        setFavIcon(FavIcon)
    }, []);

    const scroll = (ref) => {
        let element = document.getElementById(ref);
        const header = document.querySelector('.header-wrapper');
        const elementOffsetFromTop = element.getBoundingClientRect().top;
        const paddingBottom = vwToPixel(10);
        const headerHeight = header.clientHeight;
        const windowOffsetFromTop = window.pageYOffset;
        const y = 
            elementOffsetFromTop -
            (headerHeight + paddingBottom) + windowOffsetFromTop;
        window.scrollTo({ behavior: "smooth", top: y });
    };

    const contactFormHandler = () => {
        setFirstTry(false);
        if(!validateForm()){
            return;
        }

        const onSuccess = () => {
            resetForm();
            setFirstTry(true);
        }
        

        const props = {
            onSuccess,
            payload: {
                fullname: form.fullname.value,
                email: form.email.value,
                message: form.message.value
            }
        };
        Api.contactForm(props);
    };    

    const swiperOptions = {
        autoplay: {
            delay: 3000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false
        },
        loop: true,
        dir: 'ltr',
        slidesPerView: 1,
        // noSwiping: true,
        modules: [Navigation, Pagination, Autoplay],
        navigation: !deviceState.notDesktop,
        pagination: {
            clickable: !deviceState.notDesktop,
            dynamicBullets: true,
        },
    };

    const featuresOptions = {
        className: 'features-swiper',
        dir: 'ltr',
        modules: deviceState.isMobile ? [EffectCards]:undefined,
        effect: deviceState.isMobile ? "cards":undefined,
        grabCursor: true,
        initialSlide: Math.floor(features.length / 2),
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 20
            },
        }
    }

    const showError = (field) => {
        return !firstTry && !form[field]?.isValid;
    };

    return (
        <div className={'home-screen-wrapper'}>
            <Header onClick={scroll} />
            <SplashImage id="home" />
            <Section className={'home-main-section'} id="features">
                <div className={'main-features-info'}>
                    <h3>{texts['web_home_main_section_title']}</h3>
                    <p>
                        {texts['web_home_main_section_description']}
                    </p>
                </div>
                <Swiper {...featuresOptions} >
                    {features.map((feature, idx) => (
                        <SwiperSlide className="feature-slide-inner" key={`feature-${feature.id}-${idx}`}>
                                <div className="main-feature-icon-wrapper">
                                    <feature.image/>
                                </div>
                                <div className={`main-features-text-wrapper ${texts['DIRECTION']}`}>
                                    <h5>{texts[feature.title]}</h5>
                                    <p>{texts[feature.description]}</p>
                                </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Section>
            <Section className={`home-feature-section ${texts['DIRECTION']}`}>
                <div className="clipped-image-wrapper" > 
                    <div className="border bottom right-up" />
                    <div className={'feature-img-wrapper right-up'}>
                        <img className="image" src={'https://firebasestorage.googleapis.com/v0/b/trekit-ab.appspot.com/o/web-statics%2Fhome-main-feature-first.png?alt=media&token=05980f73-93c6-437e-8e0d-64e51494a57c'} />
                    </div>
                </div>
                <div className={'feature-text-wrapper'}>
                    <h3>{texts['web_extended_feature_one_title']}</h3>
                    <p>
                        {texts['web_extended_feature_one_description']}
                    </p>
                </div>
                <div className="third-fix" />
            </Section>
            <Section className={`home-feature-section reversed ${texts['DIRECTION']}`}>
                <div className="clipped-image-wrapper">
                    <div className="border bottom left-up" />
                    <div className={'feature-img-wrapper left-up'}>
                        <img className="image" src={'https://firebasestorage.googleapis.com/v0/b/trekit-ab.appspot.com/o/web-statics%2Fhome-main-feature-second.png?alt=media&token=98448b61-0bea-4f9a-bb9b-41f85b6540da'} />
                    </div>
                </div>
                <div className={'feature-text-wrapper'}>
                    <h3>{texts['web_extended_feature_two_title']}</h3>
                    <p>
                        {texts['web_extended_feature_two_description']}
                    </p>
                </div>
                <div className="third-fix" />
            </Section>
            <Section className={'home-gallery-section'} id="gallery">
                <Swiper {...swiperOptions} className='home-main-carousel'>
                    {imagesArray.map((image, idx) => (
                        <SwiperSlide key={`carousel-image-${idx}`}>
                            <img src={image} alt='/' className="in-swiper-image"/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Section>
            <Section className={`home-contact-section ${texts['DIRECTION']}`} id="contact">
                    <div className={'contact-form-wrapper'}>
                        <h2>{texts['web_home_contact_section_title']}</h2>
                        <FloatInput 
                            required
                            className='contact-form-input'
                            errMsg={form.fullname?.errMsg}
                            label={texts['field_full_name']}
                            value={form.fullname?.value}
                            name={'fullname'}
                            onChange={onFormChange}
                            showError={showError('fullname')}
                        />
                        <FloatInput 
                            required
                            className='contact-form-input'
                            errMsg={form.email?.errMsg}
                            label={texts['field_email']}
                            value={form.email?.value}
                            name={'email'}
                            rules={['email','required']}
                            onChange={onFormChange}
                            showError={showError('email')}
                        />
                        <FloatTextArea
                            required
                            className='contact-form-textarea'
                            errMsg={form.message?.errMsg}
                            label={texts['field_message']}
                            value={form.message?.value}
                            name={'message'}
                            rules={['required']}
                            onChange={onFormChange}
                            showError={showError('message')}
                        />
                        <Button 
                            className="contact-submit-button"
                            onClick={contactFormHandler}
                        >
                            {texts['action_button_send']}
                        </Button>
                    </div>
                    <div className="clipped-image-wrapper">
                        <div className="border top left-up-top" />
                        <div className={'contact-us-image left-up-top'}>
                            <img className="image" src={ContactImg} alt='Guy talking on the phone'/>
                        </div>
                    </div>
            </Section>
            <Footer />
        </div>
    );
};

export default Home;
