import React from "react";
import "./SplashImage.scss";
import Mountain from "../../../assets/mountain_splash.svg";
import MobileMock from 'assets/mobile-mock.png';
import Fireplace from "../../../assets/fireplace.svg";
import useLanguage from "hooks/useLanguage";

const SplashImage = (props) => {
    const texts = useLanguage();
    
    return (
        <div className={'splash-section-wrapper'} id={props.id}>
            <img src={Mountain} alt="Mountain background" className="splash-section-mountain" />
            <div className={'splash-section-content'}>
                <div className={'splash-section-left-side'}>
                    <h1 className={'splash-section-title'}>
                        {texts['web_splash_section_catch_phrase']}
                        <span className={'splash-section-logo'}>{texts['app_name']}</span>
                    </h1>
                    <p className={'splash-section-description'}>
                     {texts['web_splash_catch_phrase']}
                    </p>
                </div>
                <div className={'splash-section-right-side'}>
                    <div className="splash-section-mock-wrapper">
                        <img src={MobileMock} alt='App mock' />
                    </div>
                    <div className={'illustration'}>
                        <img
                            src={Fireplace}
                            alt="Illustration of a man and a woman sitting next to a fireplace"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SplashImage;
