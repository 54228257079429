import React, { useEffect, useState } from "react";
import "./index.scss";

/* ---------------- Redux ---------------- */
import { useDispatch, useSelector } from "react-redux";

/* ---------------- Constants ---------------- */
import popupTypes   from "constants/popup-types";

/* ---------------- Popups ---------------- */
import BasicPopup           from "./basic";
import LanguagesManager     from "./languages-manager";
import ProtectedParameters  from "./protected-parameters";
import AddAlert             from "./add-alert";
import ChangeLanguagePopup from "./change-language";
import ObjectEditor from "./object-editor";
import Actions from "redux/actions";
import TreksTable from "./treks-table";

const Popups = () => {
    const popupsArray = useSelector((store) => store.popupsArray);
    const unsavedPopup = useSelector(store => store.unsavedPopup);
    const [showUp, setShowUp] = useState(false);
    const [close, setClose] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowUp(true);
        }, 50);
        return () => {
            setShowUp(false);
        }
    }, [])

    const getPopupComponent = (key, type, payload) => {
        const popupProps = {
            key: key,
            payload: payload,
            close: close,
            closePopup: notifyOverlay,
        }

        let popupComponents = {
            [popupTypes.BASIC]: { component: BasicPopup, props: popupProps },
            [popupTypes.LANGUAGES]: { component:  LanguagesManager, props: popupProps },
            [popupTypes.PARAMETERS]: { component:  ProtectedParameters, props: popupProps },
            [popupTypes.ADD_ALERT]: { component:  AddAlert, props: popupProps },
            [popupTypes.CHANGE_LANGUAGE]: { component:  ChangeLanguagePopup, props: { ...popupProps, animated: true } },
            [popupTypes.OBJECT_EDITOR]: { component:  ObjectEditor, props: popupProps },
            [popupTypes.TREKS_TABLE]: { component:  TreksTable, props: popupProps },
        };
        const requiredPopup = type in popupComponents
                                ? popupComponents[type]
                                : popupComponents[popupTypes.BASIC];
        return RenderPopup(requiredPopup.component, requiredPopup.props);
    };

    const renderPopups = () => {
        let popupsToRender = popupsArray.map((popup) => {
            let key = `${popup.type}_${popup.payload}`;
            return getPopupComponent(key, popup.type, popup.payload);
        });
        return popupsToRender[0];
    };

    const notifyOverlay = () => {
      setClose(true);
      setShowUp(false);
    };
 

    const backdropFade = showUp ? 'fade-in':'';
    return (
        <div className={`popup-wrapper`}>
            <div className={`backdrop ${backdropFade}`} onClick={notifyOverlay} />
            <div className="overlay">
                {renderPopups()}
            </div>
        </div>
    );
};

export default Popups;

const RenderPopup = (Popup, props) => {
    const dispatch = useDispatch();
    const { close = false, animated = false } = props;
    
    useEffect(() => {
        if(close && !animated){
            dispatch(Actions.removePopup());
        }
    }, [close, animated]);

    return <Popup {...props} />
};