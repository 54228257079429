import React    from "react";

/* ---------- Router ---------- */
import { BrowserRouter }    from "react-router-dom";

/* ---------- ReactDOM ---------- */
import * as ReactDOM    from "react-dom/client";

/* ---------- Redux ---------- */
import { Provider } from "react-redux";
import Store        from "./redux";

/* ---------- Components ---------- */
import App  from "./App";

/* ---------- Assets ---------- */
import "./index.css";

/* ---------- Utilities ---------- */
import reportWebVitals from "./reportWebVitals";
import deviceState from "utilites/deviceState";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={Store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
