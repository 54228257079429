import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCiyk_81al29W14Ld3s0LTXPpYiXbWwFME",
    authDomain: "trekit-ab.firebaseapp.com",
    databaseURL:
        "https://trekit-ab-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "trekit-ab",
    storageBucket: "trekit-ab.appspot.com",
    messagingSenderId: "236280200924",
    appId: "1:236280200924:web:797ac2fefb43d2c9effe19",
    measurementId: "G-JKVSFXLPC6",
};

const app = initializeApp(firebaseConfig);

export default app;
