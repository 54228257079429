import Store from '../redux/index';

export const generateUniqueKey = (num: number) => {
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let res = [];
    for (let i = 0; i < num; i++) {
        let index = Math.random() * characters.length;
        res.push(characters.charAt(index));
    }
    return res.join("");
};

export function Validate(value, rules) {
    const Validations = {
        no_validation: {
            valid: (val) => true,
            msg: "",
        },
        required: {
            valid: (val) => val !== "" && val !== undefined,
            msg: 'error_required',
        },
        address: {
            valid: (val) => val !== "" && val !== undefined,
            msg: "error_address",
        },
        email: {
            valid: (val) =>
                /^([\w!#$%&'*+-/=?^`{|}~]+\.)*[\w!#$%&'*+-/=?^`{|}~]+@((((([a-zA-Z0-9]{1}[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]{1})|[a-zA-Z])\.)+[a-zA-Z]{2,6})|(\d{1,3}\.){3}\d{1,3}(:\d{1,5})?)$/.test(
                    val
                ),
            msg: 'error_email',
        },
        full_name: {
            valid: (val) =>
                /^([a-z | A-Z | א-ת]{2,})+\s+([a-z | A-Z | א-ת]{2,})+$/.test(val),
            msg: 'error_full_name',
        },
        checkbox: {
            valid: (val) => val,
            msg: 'error_required',
        },
        required_select: {
            valid: (val) => {
                if (val === undefined) return false;
                return val !== -1;
            },
            msg: 'error_required',
        },
    };

    let valid = true;
    let msg = "";

    for (let rule of rules) {
        if (typeof rule === "function") {
            valid = rule();
            msg = 'error_required';
        } else {
            if (!Validations[rule].valid(value)) {
                valid = false;
                msg = Validations[rule].msg;
                break;
            }
        }
    }

    return { valid: valid, msg: msg };
}

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};


export const deepCloneObjectsArray = (array) => {
    let res = [];
    for(const item of array){
        res.push(deepClone(item));
    }
    return res;
}

export const deepClone = (obj) => {
    if(typeof obj !== 'object' && !Array.isArray(obj)){
        return obj;
    }
    if(Array.isArray(obj)){
        return [...obj];
    }
    let res = {};
    for(const key of Object.keys(obj)){
        res[key] = deepClone(obj[key]);
    }
    return res;
};

export const reOrderArray = (array, current, target, orderByField) => {
    const isDesc = current > target;
    return array.map((item) => { 
        if(item[orderByField] === current){
            item[orderByField] = target;
        } else {       
            if(isDesc){
                if(item[orderByField] < current && item[orderByField] >= target){
                    item.order_num += 1;
                }   
            } else {
                if(item[orderByField] > current && item[orderByField] <= target){
                    item[orderByField] -= 1;
                }
            }
        }
        return item;
    });
}

export const setFavIcon = (icon) => {
    const element = document.getElementById('favicon');
    element.href = icon;
};

export const vw = (size, base) => {
    if(base){
        return size / base * 100;
    }
    const windowWidth = window.innerWidth;
    if(windowWidth <= 320) {
        return size / 320 * 100;
    }
    if(windowWidth <= 768) {
        return size / 768 * 100;
    }
    if(windowWidth <= 1200) {
        return size / 1200 * 100;
    }
    if(windowWidth <= 1500) {
        return size / 1500 * 100;
    }
    if(windowWidth <= 1920) {
        return size / 1920 * 100;
    }
};

export const vwToPixel = (size, base) => {
    if(base){
        return size * base / 100;
    }
    const windowWidth = window.innerWidth;
    if(windowWidth <= 320) {
        return size * 320 / 100;
    }
    if(windowWidth <= 768) {
        return size * 768 / 100;
    }
    if(windowWidth <= 1200) {
        return size * 1200 / 100;
    }
    if(windowWidth <= 1500) {
        return size * 1500 / 100;
    }
    return size * 1920 / 100;
}