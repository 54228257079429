import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';
import Api from 'api/requests';
import Loader from 'components/UI/Loader';
import useLanguage from 'hooks/useLanguage';
import React, { useEffect, useState } from 'react';
import './index.scss';


const TreksTable = (props) => {
    const texts = useLanguage();
    const { payload = {}, closePopup = () => {} } = props;
    const { 
        userId, 
        displayName, 
        method = 'getTreksByUser', 
        headlineText = 'web_cms_user_treks_headline',
        columns = []
    } = payload;
    const [treks, setTreks] = useState([]);
    const [page, setPage] = useState(0);
    const [isDone, setIsDone] = useState(true);

    useEffect(() => {
        const onSuccess = (res) => {
          setTreks(res.treks);
          setIsDone(res.isDone)
        }

        const props = {
            onSuccess,
            payload: {
                userId,
                page: page
            }
        };
        Api[method](props);
    }, [page, userId, method]);

    const nextPage = () => {
      setPage(prev => prev + 1);
    };

    const previousPage = () => {
      setPage(prev => prev - 1);
    };

    const showLeftArrow = (texts['DIRECTION'] === 'ltr' && page > 0) || (texts['DIRECTION'] === 'rtl' && !isDone);
    const showRightArrow = (texts['DIRECTION'] === 'ltr' && !isDone) || (texts['DIRECTION'] === 'rtl' && page > 0);
    return (
        <div className='user-treks-popup'>
            <div className='user-treks-header'>
                <span>{texts[headlineText].replace('[_]', displayName)}</span>
                <div className='pagination-controls'>
                    <div className='pagination-control-wrapper'>
                        {showLeftArrow && <Icon path={mdiArrowLeft} className={'pagination-control'} onClick={texts['DIRECTION'] === 'ltr' ? previousPage:nextPage}/>}
                    </div>
                    <div className='pagination-control-wrapper'>
                        {showRightArrow && <Icon path={mdiArrowRight} className={'pagination-control'} onClick={texts['DIRECTION'] === 'ltr' ? nextPage:previousPage}/>}
                    </div>
                </div>
            </div>
            <div className='user-treks-wrapper'>
                <div className='trek-entry header'>
                    {columns.map((column, idx) => (
                        <div className='trek-entry-column' key={`treks-result-column-${idx}`}>
                            {texts[column.textKey]}
                        </div>
                    ))}
                </div>
                {treks?.map((trek) => {
                    return (
                        <div className='trek-entry' key={`user-treks-table-entry-${trek._id}`}>
                            {columns.map((column, idx) => (
                                <div 
                                    key={`table-entry-column-${trek._id}-${idx}`}
                                    className={`trek-entry-column ${ typeof column.onClick === 'function' ? 'link':''}`}
                                    onClick={() => {
                                        closePopup();
                                        column.onClick(trek)
                                    }}
                                >
                                    {column.data(trek[column.key])}
                                </div>
                            ))}
                        </div>
                    );
                })}
                {treks.length === 0 && (
                    <div className='no-treks-found'>
                        <Loader/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TreksTable;