import Api from 'api/requests';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.scss';

const Splash = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [done, setDone] = useState(false);
    useEffect(() => {
        getUser(location.pathname);
    }, []);

    const getGeneralDeclaration = (path) => {
        const onSuccess = () => {
            navigate(path);
            setTimeout(() => {
                setDone(true)
            }, 750);
        };

        const props = {
            onSuccess,
            config: {
                method: 'GET'
            }
        }
        Api.generalDeclaration(props);
    };
    

    const getUser = (path) => {
        const token = localStorage.getItem('@silentLoginToken');
        if(token){
            const onSuccess = () => {
                getGeneralDeclaration(path);
            };

            const props = {
                onSuccess,
                payload: {
                    token
                }
            }
            Api.silentLogin(props);
        } else { 
            getGeneralDeclaration();
        }
    };
    
    const hideClassName = done ? "hide":"";
    return (
        <div className={`splash-screen-wrapper ${hideClassName}`}>
            <h2 className='splash-logo'>TrekIt</h2>
        </div>
    );
};

export default Splash;