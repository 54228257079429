import useLanguage from 'hooks/useLanguage';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'redux/actions';
import './index.scss';


const ChangeLanguagePopup = (props) => {
    const { close = false, closePopup = () => {} } = props;
    const texts = useLanguage();
    const dispatch = useDispatch();
    const language = useSelector(store => store.localStorage.language);
    const languages = useSelector(store => store.gd.translations);
    const [closeClass, setCloseClass] = useState('');

    useEffect(() => {
        if(close) {
            setCloseClass('close');
            setTimeout(() => {
                dispatch(Actions.removePopup());
            }, 300);
        } else {
            setCloseClass('slide-up');
        }
    }, [close]);

    const initiateLanguageChange = (lang) => {
        const transition = document.querySelector('.language-change');
        transition.classList.add('start');
        closePopup();
        setTimeout(() => {
            dispatch(Actions.setLocalStorage({ language: lang }));
            localStorage.setItem('@lang', lang);
            setTimeout(() => {
                transition.classList.remove('start');
            }, 500);
        }, 500);
    };
    

    return (
        <div className={`change-language-popup ${closeClass}`}>
            <h2>{texts['select_language']}</h2>
            <div className='languages-wrapper'>
                {Object.keys(languages).map((lang, idx) => {
                    const activeClass = language === lang ? 'active':'';
                    return (
                    <button 
                        key={`language-option-${lang}-${idx}`}
                        className={`language-option ${activeClass}`}
                        onClick={initiateLanguageChange.bind(this, lang)}
                    >
                        {languages[lang].LANGUAGE}
                    </button>
                    )
                })}
            </div>
        </div>
    );
};

export default ChangeLanguagePopup;