import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mdi/react";
import { mdiClose, mdiEarth, mdiMenu } from "@mdi/js";
import { useEffect, useState } from "react";
import { NAVIGATION } from "constants/routes";
import useLanguage from "hooks/useLanguage";
import Actions from "redux/actions";
import popupTypes from "constants/popup-types";

const Header = (props) => {
    const { onClick = () => {} } = props;
    const texts = useLanguage();
    const dispatch = useDispatch();
    const deviceState = useSelector(store => store.deviceState);
    const [scrollStyle, setScollStyle] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const [indicatorWidth, setIndicatorWidth] = useState(0);

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler);
        return () => {
            document.removeEventListener('scroll', scrollHandler);
        }
    }, []);

    const scrollHandler = (e) => {
        const root =  document.querySelector('#root').clientHeight;
        const headerHeight = document.querySelector('.header-wrapper').clientHeight;
        const y = window.pageYOffset;
        const innerHeight = document.body.clientHeight;
        let res = Math.abs(Math.floor(y/(innerHeight-root) * 100));
        setIndicatorWidth(res);
        if(y > headerHeight){
            setScollStyle('scrolled');
        } else {
            setScollStyle('');
        }
    };
    

    const toggleMenu = () => {
        setMenuOpen(prev => !prev);
    };
    
    const clickHandler = (name) => {
        if(deviceState.notDesktop){
            toggleMenu();
        }
        onClick(name);
    };
    

    const changeLanguageHandler = () => {
        toggleMenu();
        dispatch(Actions.addPopup({
            type: popupTypes.CHANGE_LANGUAGE
        }));
    };
    

    const menuClass = menuOpen ? 'open':'';
    return (
        <div className={`header-wrapper ${scrollStyle}`}>
            <h1 className={'header-logo'}>{process.env.REACT_APP_NAME}</h1>
            <div className={`burger-menu-wrapper ${menuClass}`}>
                <div className={`burger-menu-backdrop`} onClick={toggleMenu}/>
                <div className={`burger-menu-overlay`}>
                    <div className="burger-menu-close-wrapper">
                        <Icon path={mdiClose} className='burger-menu-close' onClick={toggleMenu} />
                    </div>
                    {Object.keys(NAVIGATION).map((item, idx) => (
                        <NavigationItem
                            key={`burger-navigation-item-${idx}-${item}`}
                            name={item}
                            title={texts[NAVIGATION[item]]}
                            onClick={clickHandler}
                        />
                    ))}
                    <div className="burger-menu-change-language" onClick={changeLanguageHandler}>
                        <Icon path={mdiEarth} className='burger-menu-language' onClick={toggleMenu} />
                        <span className="change-language-text">{texts['account_settings_change_language']}</span>
                    </div>
                </div>
            </div>
            <div className='burger-menu-icon-wrapper' onClick={toggleMenu}>
                <Icon path={mdiMenu} className='burger-menu-icon' />
            </div>
            <div className="scroll-indicator-wrapper">
                <div className="scroll-indicator" style={{ width: indicatorWidth + "%" }}/>
            </div>
        </div>
    );
};

export default Header;


const NavigationItem = (props) => {
    const { onClick = () => {}, title, name } = props;

    return <div onClick={onClick.bind(this, name)} className='burger-menu-item'>{title}</div>
}
