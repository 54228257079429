export const baseTitle = 'TrekIt - Explore Together!';
export const CMSTitle = `CMS | ${baseTitle}`;
export const CMSSubsTitles = {
    root: `${CMSTitle}`,
    gd: `Site Settings | ${CMSTitle}`,
    reports: `Reports | ${CMSTitle}`,
    content: `Content | ${CMSTitle}`,
}
export const CMSTitles = (child, father) => {
    const fatherTitle = CMSSubsTitles[father];
    switch (child) {
        case 'home': return `Dashboard | ${fatherTitle}`;
        case 'login': return `Login | ${fatherTitle}`;
        case 'users': return `Users | ${fatherTitle}`;
        case 'user': return `Users | ${fatherTitle}`;
        case 'treks': return `Treks | ${fatherTitle}`;
        case 'alerts': return `Alerts | ${fatherTitle}`;
        case 'comments': return `Comments | ${fatherTitle}`;
        case 'translations': return `Translations | ${fatherTitle}`;
        case 'categories': return `Categories | ${fatherTitle}`;
        case 'parameters': return `Parameters | ${fatherTitle}`;
        default: return baseTitle;
    }
}