import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import popupTypes from 'constants/popup-types';
import useTitle from 'hooks/useTitle';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'redux/actions';
import AlertEntry from './components/AlertEntry';
import './index.scss';


const Alerts = (props) => {
    useTitle(props);
    const dispatch = useDispatch();
    const alerts = useSelector(store => store.gd.alerts);
    const languages = useSelector(store => store.gd.translations);
    const selectedLanguage = useSelector(store => store?.cms?.selectedAlertLanguage) || 'en';

    const onLanguageChange = (e) => {
        const { value } = e.target;
        dispatch(Actions.setAlertLanguage(value));
    };
    
    const addAlertHandler = () => {
        dispatch(Actions.addPopup({
            type: popupTypes.ADD_ALERT
        }))
    };
    

    return (
        <div className='cms-alerts-wrapper'>
            <div className='choose-language-area'>
                <span className='title'>
                    Choose a language:{' '}
                </span>
                <select defaultValue={selectedLanguage} onChange={onLanguageChange}>
                    {Object.keys(languages).map((lang, idx) => (
                        <option value={lang} key={`language-selector-${lang}-${idx}`}>{languages[lang].LANGUAGE}</option>
                    ))}
                </select>
            </div>
            <div className='alerts-wrapper'>
                <button className='add-an-alert-wrapper' onClick={addAlertHandler}>
                    <Icon path={mdiPlus} className='add-icon' />
                </button>
                {alerts?.map((alert, idx) => (
                    <AlertEntry
                        {...alert}
                        key={`alert-${alert._id}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default Alerts;