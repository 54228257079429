import React    from 'react';

/* ---------- React-Router ---------- */
import { NavLink }  from 'react-router-dom';

/* ---------- Constants ---------- */
import * as Routes  from 'constants/routes';

/* ---------- Components ---------- */
import DropdownMenu from '../dropdown-menu';

/* ---------- Assets ---------- */
import './index.scss';
import { useSelector } from 'react-redux';
import useLanguage from 'hooks/useLanguage';

const Header = () => {
    const texts = useLanguage();
    const userData = useSelector(store => store.userData);
    return (
        <div className='cms-header-wrapper'>
            <NavLink to={Routes.CMS} className="logo-text">{texts['app_name']}</NavLink>
            <ul className="nav-menu">
                {userData && Object.keys(Routes.CMS_SUBS).map((key, idx) => (
                    key !== "root" ? (
                    <DropdownMenu
                        key={`dropdown_section_${key}`}
                        text={texts[`web_cms_header_nav_${key}`]}
                        items={Routes.CMS_SUBS[key].subs}   
                    />
                    ):(<React.Fragment key={idx}></React.Fragment>)
                ))}
            </ul>
        </div>
    );
};

export default Header;