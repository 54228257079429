import React, { useRef, useState }  from 'react';

/* ---------- Redux ---------- */
import Actions                      from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';

/* ---------- Assets ---------- */
import './index.scss';
import { AiOutlineWarning, AiFillEdit, AiOutlineDelete, AiOutlineSave } from 'react-icons/ai';

/* ---------- API ---------- */
import { updateCategoryImage }  from 'lib/storage-api';

/* ---------- Constants ---------- */
import * as NOTIFICATIONS   from 'constants/notification-types';

const NO_IMAGE = 'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg';

const TableRow = (props) => {
    const { data, onRemove} = props;
    const imageRef = useRef();
    const mapPinRef = useRef();
    const dispatch = useDispatch();
    const languages = useSelector(store => store.gd.translations);
    const sortedLanguages = Object.keys(languages).sort((curr, prev) => languages[curr].ORDER_NUM < languages[prev].ORDER_NUM ? -1:1);
    const [localFields, setLocalFields] = useState({...data});

    const localOnChange = (e) => {
        const { value, name } = e.target;
        const updatedFields = { ...localFields };
        updatedFields.title = { ...localFields.title };
        if(sortedLanguages.includes(name)){
            updatedFields.title[name] = value;
        } else {
            updatedFields[name] = value;
        }
        setLocalFields(updatedFields);
    }

    const onOrderIdChange = (e) => {
        const { value } = e.target;
        const val = parseInt(value);
        dispatch(Actions.reorderCategories({
            id: data.id,
            order_num: val
        }));
        dispatch(
            Actions.addNotification({
                title: "Success",
                text: `Categories order changed!`,
                status: NOTIFICATIONS.SUCCESS,
                duration: 3000,
            })
        );
    };
    
    const saveToGd = () => {
        let difference = false;
        for(const key of Object.keys(data)){
            if(data[key] !== localFields[key]){
                difference = true;
                break;
            }
        }
        if(difference){
            dispatch(Actions.editCategory(localFields));
            dispatch(
                Actions.addNotification({
                    title: "Updated",
                    text: `Category updated successfully!`,
                    status: NOTIFICATIONS.SUCCESS,
                    duration: 3000,
                })
            );
        } else {
            dispatch(
                Actions.addNotification({
                    title: "Error",
                    text: `Nothing to update`,
                    status: NOTIFICATIONS.ERROR,
                    duration: 3000,
                })
            );
        }
    };
      
    const showUnsavedIndicator = (field) => {
        if(sortedLanguages.includes(field)) return localFields.title[field] !== data.title[field];
        return localFields[field] !== data[field];
    };

    const handleEditClick = (pin = false) => {
        if(pin){
            mapPinRef.current.click();
            return;
        }
        imageRef.current.click();
    };

    const handleImageSelect = async (e) => {
        const { files } = e.target;
        let file = files[0];
        const downloadUrl = await updateCategoryImage(data.title.en.toLowerCase(), file);
        dispatch(Actions.editCategory({
            id: data.id,
            newIcon: downloadUrl
        }));
        dispatch(
            Actions.addNotification({
                title: "Success",
                text: `Category image changed successfully!`,
                status: NOTIFICATIONS.SUCCESS,
                duration: 3000,
            })
        );
    };

    const handleMapPinSelect = async (e) => {
        const { files } = e.target;
        let file = files[0];
        const downloadUrl = await updateCategoryImage(data.title.en.toLowerCase(), file);
        dispatch(Actions.editCategory({
            id: data.id,
            newIcon: downloadUrl
        }));
        dispatch(
            Actions.addNotification({
                title: "Success",
                text: `Category image changed successfully!`,
                status: NOTIFICATIONS.SUCCESS,
                duration: 3000,
            })
        );
    };

    return (
        <tr>
            <td className='number'>
                <input type={"number"} value={localFields._id} disabled/>
            </td>
            <td className='icon'>
                <img src={localFields.icon ? localFields.icon:NO_IMAGE} alt="/" className='thumbnail'/>
                <input type={"file"} ref={imageRef} className="img-input-hidden" onChange={handleImageSelect} />
                <AiFillEdit className='edit-icon' size={14} onClick={handleEditClick}/>
                <img src={localFields.icon ? localFields.icon:NO_IMAGE} alt="/" className='magnifying'/>
            </td>
            <td className='icon'>
                <img src={localFields.mapPin ? localFields.mapPin:NO_IMAGE} alt="/" className='thumbnail'/>
                <input type={"file"} ref={mapPinRef} className="img-input-hidden" onChange={handleMapPinSelect} />
                <AiFillEdit className='edit-icon' size={14} onClick={handleEditClick.bind(this, true)}/>
                <img src={localFields.mapPin ? localFields.mapPin:NO_IMAGE} alt="/" className='magnifying'/>
            </td>
            <td className='languages-wrapper'>
                <div className='languages-names'>
                    {sortedLanguages.map((lang, idx) => (
                    <React.Fragment key={`edit_${lang}_${idx}`}>
                        <textarea className={languages[lang].DIRECTION}  value={localFields.title[lang]} onChange={localOnChange} name={lang}/>
                        {showUnsavedIndicator(lang) && <AiOutlineWarning color='#ff0000' className='indicator'/>}
                    </React.Fragment>
                    ))}
                </div>
            </td>
            <td className='number'>
                <input type={"number"} value={data.order_num} onChange={onOrderIdChange} name="order_num" />
                {showUnsavedIndicator('order_num') && <AiOutlineWarning color='#ff0000' className='indicator'/>}
            </td>
            <td className='number'>
                <AiOutlineDelete size={20} className='remove-btn' onClick={onRemove.bind(this, data.id)} />
                <AiOutlineSave size={20} className='save-btn' onClick={saveToGd} />
            </td>
        </tr>
    );
};

export default TableRow;