import ApiManager from "api";
import Actions from "../../redux/actions";
import store from '../../redux'

class Api extends ApiManager {
    constructor() {
        if (!Api.instance) {
            super();
            Api.instance = this;
        }
        return Api.instance;
        // return (Api.instance = this);
    }

    generalDeclaration = (props = {}) => {
        const onSuccess = (response) => {
           store.dispatch(Actions.setGeneralDeclaration(response));
           typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        return this._execute(props, "generalDeclaration", onSuccess);
    };

    addTranslation = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setTranslation({
                key: response._id,
                value: response.translations
            }));
           typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "addTranslation", onSuccess, onFailure, onError);
    };

    removeTranslation = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setTranslation({
                key: response.key
            }));
           typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "removeTranslation", onSuccess, onFailure, onError);
    };

    addParameter = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setParameter({
                key: response._id,
                value: response.value
            }));
           typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "addParameter", onSuccess, onFailure, onError);
    };

    removeParameter = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setParameter({
                key: response.key
            }));
           typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "removeParameter", onSuccess, onFailure, onError);
    };

    getTreksByQuery = (props = {}) => {
        const onSuccess = (response) => {
           typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "getTreksByQuery", onSuccess, onFailure, onError);
    };

    getTrekById = (props = {}) => {
        const onSuccess = (response) => {
           typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "getTrekById", onSuccess, onFailure, onError);
    };

    getUserById = (props = {}) => {
        const onSuccess = (response) => {
           typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "cms/getUserById", onSuccess, onFailure, onError);
    };

    getUsersByQuery = (props = {}) => {
        const onSuccess = (response) => {
           typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "getUsersByQuery", onSuccess, onFailure, onError);
    };

    logIn = (props = {}) => {
        const onSuccess = (response) => {
            localStorage.setItem("@silentLoginToken", response.silentLoginToken);
            store.dispatch(Actions.setAccessToken(response.token));
            store.dispatch(Actions.setUserData(response.user));
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "logIn", onSuccess, onFailure, onError);
    };

    silentLogin = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setAccessToken(response.token));
            store.dispatch(Actions.setUserData(response.user));
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "silentLogin", onSuccess, onFailure, onError);
    };

    refreshToken = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setAccessToken(response));
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "refreshToken", onSuccess, onFailure, onError);
    };

    removeAlert = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setAlert({ ...response }));
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "removeAlert", onSuccess, onFailure, onError);
    };

    addAlert = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setAlert({ ...response }));
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "addAlert", onSuccess, onFailure, onError);
    };

    toggleAlert = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setAlert({ ...response }));
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "toggleAlert", onSuccess, onFailure, onError);
    };

    contactForm = (props = {}) => {
        const onSuccess = (response) => {
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "contactForm", onSuccess, onFailure, onError);
    };

    getContacts = (props = {}) => {
        const onSuccess = (response) => {
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "getContacts", onSuccess, onFailure, onError);
    };

    getTreksByUser = (props = {}) => {
        const onSuccess = (response) => {
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "getTreksByUser", onSuccess, onFailure, onError);
    };

    getTreksSavedByUser = (props = {}) => {
        const onSuccess = (response) => {
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "getTreksSavedByUser", onSuccess, onFailure, onError);
    };

    updateTrekDetails = (props = {}) => {
        const onSuccess = (response) => {
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "updateTrekDetails", onSuccess, onFailure, onError);
    };

    updateUserDetails = (props = {}) => {
        const onSuccess = (response) => {
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "updateUserDetails", onSuccess, onFailure, onError);
    };

    getReportsByType = (props = {}) => {
        const onSuccess = (response) => {
            typeof props.onSuccess === 'function' && props.onSuccess(response);
        };

        const onError = (error) => {
            typeof props.onError === 'function' && props.onError(error);
        };

        const onFailure = (error) => {
            typeof props.onFailure === 'function' && props.onFailure(error);
        }
        
    
        return this._execute(props, "cms/getReportsByType", onSuccess, onFailure, onError);
    };
}

const instance = new Api();
Object.freeze(instance);
export default instance;