import React, { useState }  from 'react';
import Api                  from 'api/requests';

/* ---------- Redux ---------- */
import { useDispatch, useSelector } from 'react-redux';
import Actions                      from 'redux/actions';

/* ---------- Assets ---------- */
import './index.scss';

/* ---------- Components ---------- */
import Button   from 'components/UI/Button';

/* ---------- Constants ---------- */
import popupTypes           from 'constants/popup-types';
import * as Notifications   from 'constants/notification-types';
import useTitle from 'hooks/useTitle';
import useLanguage from 'hooks/useLanguage';

const allowedTypes = [
    'number',
    'string',
    'array',
    'object'
];

const Parameters = (props) => {
    useTitle(props);
    const texts = useLanguage();
    const parameters = useSelector(store => store.gd.parameters);
    const protectedParameters = parameters.protectedParameters;
    const [query, setQuery] = useState("");
    const dispatch = useDispatch();

    const onSubmit = (key, type, Value) => {
        const formatedKey = key.replace(' ', '_');
        const isNew = Object.keys(parameters).includes(formatedKey);
        if(!isNew && protectedParameters.includes(formatedKey)){
            dispatch(
                Actions.addNotification({
                    title: "Error",
                    text: `This key is protected, please use another one`,
                    status: Notifications.ERROR,
                    duration: 5000,
                })
            );
            // setFields(prev => ({...prev, key: ''}));
            return;
        }
        let value;
        switch(type){
            case 'number':
                if(Value.includes('.')){
                    value = parseFloat(Value)
                } else {
                    value = parseInt(Value);
                }
                break;
            case 'string':
                value = Value;
                break;
            case 'array':
                if(!Value.includes(',')){
                    dispatch(
                        Actions.addNotification({
                            title: "Error",
                            text: `Arrays must be separated with commas!`,
                            status: Notifications.ERROR,
                            duration: 4000,
                        })
                    );
                    return;
                }
                value = Value.split(',');
                value = value.map((item) => item.trim());
                break;
            default:
                break;
        }
        const onSuccess = () => {
            // setFields(initialFields);
            dispatch(
                Actions.addNotification({
                    title: "Success",
                    text: `${formatedKey} ${isNew ? "added":"updated"} successfully!`,
                    status: Notifications.SUCCESS,
                    duration: 3000,
                })
            );
        };

        const onError = (error) => {
            dispatch(
                Actions.addNotification({
                    title: "Error",
                    text: error,
                    status: Notifications.ERROR,
                    duration: 3000,
                })
            );
        };

        const props = {
            onSuccess,
            onError,
            payload: {
                key: formatedKey,
                value
            }
        }
        Api.addParameter(props);
        
    };
    

    const handleRemove = (key) => {
        const onSuccess = () => {
            dispatch(
                Actions.addNotification({
                    title: "Success",
                    text: `${key} removed successfully!`,
                    status: Notifications.SUCCESS,
                    duration: 3000,
                })
            );
        };

        const props = {
            onSuccess,
            payload: {
                key
            }
        };

        Api.removeParameter(props);
    };
    
    const popProtected = () => {
        dispatch(Actions.addPopup({
            type: popupTypes.PARAMETERS
        }))
    };
     

    const onQueryChange = (e) => {
        const { value } = e.target;
        setQuery(value);
    };
    
    const filteredParameters = Object.keys(parameters).filter(key => key.includes(query));
    return (
        <div className='parameters-wrapper'>
            <div className="search-wrapper">
                <label htmlFor='query' className='label'>{texts['web_cms_translations_search']}</label>
                <input type={"text"} onChange={onQueryChange} value={query} className="input" />
            </div>
            <div className='control-btns'>
                <Button onClick={popProtected}>{texts['web_cms_parameters_buttons_edit_protected']}</Button>
                {/* <Button onClick={() => {
                    dispatch(Actions.addPopup({
                        type: popupTypes.OBJECT_EDITOR,
                        payload: {
                            initialData: {
                                numberTest: 112,
                                stringTest: '112',
                                objectTest: {
                                    a: 1,
                                    b: 'string',
                                    c: [1,2,3],
                                    d: {
                                        numberTest: 112,
                                        stringTest: '112',
                                        objectTest: {
                                            a: 1,
                                            b: 'string',
                                            c: [1,2,3], 
                                        },
                                    }
                                }
                            },
                        }
                    }))
                }}>{'temp'}</Button> */}
            </div>
            <table>
                <thead>
                    <tr>
                        <th>{texts['web_cms_table_column_key']}</th>
                        <th>{texts['web_cms_table_column_type']}</th>
                        <th>{texts['web_cms_table_column_value']}</th>
                        <th>{texts['web_cms_table_column_actions']}</th>
                    </tr>
                </thead>
                <tbody>
                    <Parameter 
                        btnText={texts['web_cms_buttons_add']}
                        onAdd={onSubmit}
                        isNew
                    />
                    {filteredParameters.map((key, idx) => (
                        <React.Fragment  key={`parameter_${key}`}>
                        {!protectedParameters.includes(key) && (
                            <Parameter 
                                btnText={texts['web_cms_buttons_save']}
                                onAdd={onSubmit}
                                onRemove={handleRemove}
                                Key={key}
                                value={parameters[key]}
                                type={typeof parameters[key]}
                            />
                        )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Parameters;

const getParameterValue = (para) => {
    if(Array.isArray(para))
        return para.join(', ');
    if(typeof para === 'object')
        return JSON.stringify(para);
    return para;
}


const Parameter = (props) => {
    // const parameters = useSelector(store => store.gd.parameters);
    const texts = useLanguage();
    const {
        onAdd = () => {},
        onRemove,
        Key,
        value,
        type,
        isNew = false,
        btnText = "Add"
    } = props;

    const [local, setLocal] = useState({
        key: Key,
        type: type,
        value: value
    });

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        const updatedLocal = JSON.parse(JSON.stringify(local));
        updatedLocal[name] = value;
        setLocal(updatedLocal);
    }
    
    const handleAdd = () => {
        setLocal({
            key: Key,
            value: value,
            type: type
        });
        onAdd(local.key, local.type, local.value);
    };

    const handleRemove = () => {
        typeof onRemove === 'function' && onRemove(local.key);
    };
    

    return (
        <tr>
            <td>
                <input type={'text'} name='key' onChange={onFieldChange} value={local.key} disabled={!isNew}/>
            </td>
            <td>
                <select name='type' onChange={onFieldChange} value={local.type}>
                    {allowedTypes.map((type) => (
                        <option 
                        key={`type_option_${type}`}
                        value={type}
                        >{texts[`web_cms_parameters_allowed_types_${type}`]}</option>
                    ))}
                </select>
            </td>
            <td>
                <textarea name={'value'} value={getParameterValue(local.value)} onChange={onFieldChange} />
            </td>
            <td>
                <Button 
                        onClick={handleAdd}
                    >
                    {btnText}
                </Button>
                {onRemove && <Button 
                    onClick={handleRemove}
                    className="remove-btn"
                >
                    {texts['web_cms_buttons_remove']}
                </Button>}
            </td>
        </tr>
    );
}
