import React from 'react';
import './index.scss';


const TreksCounter = ({ 
    value = 0, 
    onClick = () => {},
    label,
    disabled = false,
    }) => {
    return (
        <button className='treks-counter-wrapper' onClick={onClick} disabled={disabled}>
            <div className='treks-counter'>{value}</div>
            <div className='treks-counter-label'>{label}</div>
        </button>
    );
};

export default TreksCounter;