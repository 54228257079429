import React from 'react';

/* ---------- Redux ---------- */
import { useDispatch, useSelector } from 'react-redux';
import Actions                      from 'redux/actions';

/* ---------- Components ---------- */
import TableRow from './components/table-row';

/* ---------- Constants ---------- */
import * as NOTIFICATIONS   from 'constants/notification-types';

/* ---------- Assets ---------- */
import './index.scss';
import useLanguage from 'hooks/useLanguage';


const LanguagesManager = (props) => {
    const texts = useLanguage();
    const dispatch = useDispatch();
    const languages = useSelector(store => store.gd.translations);
    const parameters = useSelector(store => store.gd.parameters);
    const protectedLanguages = useSelector(store => store.gd.parameters.protectedLanguages);
    const numOfLanguages = useSelector(store => store.gd.parameters.numOfLanguages);
    const sortedCodes = Object.keys(languages).sort((curr, prev) => parseInt(languages[curr].ORDER_NUM) < parseInt(languages[prev].ORDER_NUM) ? -1:1);
    const newLanguage = {
        id: "",
        code: "",
        name: "",
        direction: ''
    };

    const onLanguageChange = (id, obj) => {
        let updatedState = {};
        const isNew = !Object.keys(languages).includes(obj.code);
        let temp = {};
        for(const key in languages) {
            if(id >= 0 ){
                if(key !== sortedCodes[id]){
                    updatedState[key] = { ...languages[key] };
                } else {
                    temp = {...languages[key]};
                }
            } else {
                updatedState[key] = { ...languages[key] };
            }
        }
        updatedState[obj.code] = temp;
        updatedState[obj.code].LANGUAGE = obj.name;
        if(!isNew){
            updatedState[obj.code].ORDER_NUM = obj.id;
        } else {
            updatedState[obj.code].ORDER_NUM = numOfLanguages + 1;
        }
        updatedState[obj.code].DIRECTION = obj.direction;
        dispatch(Actions.setGeneralDeclaration({ translations: updatedState, parameters: {...parameters, numOfLanguages: numOfLanguages + 1} }));
        dispatch(
            Actions.addNotification({
                title: "Success",
                text: `${obj.name} ${isNew ? 'added':'updated'} successfully!`,
                status: NOTIFICATIONS.SUCCESS,
                duration: 3000,
            })
        );
    }

    const onLanguageRemove = (id) => {
        let updatedState = {};
        const code = Object.keys(languages).filter((code) => languages[code].ORDER_NUM === id)[0];
        if(protectedLanguages.includes(code)){
            dispatch(
                Actions.addNotification({
                    title: "Information",
                    text: `${languages[code].LANGUAGE} is a protected language and cannot be removed`,
                    status: NOTIFICATIONS.INFO,
                    duration: 5000,
                })
            );
            return;
        }
        for(const key in languages) {
            if(key !== code){
                updatedState[key] = { ...languages[key] };
            }
        }
        dispatch(
            Actions.addNotification({
                title: "Success",
                text: `${languages[code].LANGUAGE} removed successfully!`,
                status: NOTIFICATIONS.SUCCESS,
                duration: 3000,
            })
        );
        dispatch(Actions.setGeneralDeclaration({ translations: updatedState }));
    }
    
    
    return (
        <div className='languages-manager-wrapper'>
            <table className='table'>
                <thead>
                    <tr>
                    <th>
                        #
                    </th>
                    <th>
                        {texts['web_cms_table_column_code']}
                    </th>
                    <th>
                        {texts['web_cms_table_column_language']}
                    </th>
                    <th>
                        {texts['web_cms_table_column_direction']}
                    </th>
                    <th>
                        {texts['web_cms_table_column_actions']}
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <TableRow
                        id={newLanguage.id}
                        code={newLanguage.code}
                        name={newLanguage.name}
                        direction={newLanguage.direction}
                        btnText={texts['web_cms_buttons_add']}
                        onClick={onLanguageChange}
                    />
                    {sortedCodes.map((lang, idx) => (
                        <TableRow
                            key={`${lang}&${idx}`}
                            id={languages[sortedCodes[idx]].ORDER_NUM}
                            code={sortedCodes[idx]}
                            name={languages[sortedCodes[idx]].LANGUAGE}
                            direction={languages[sortedCodes[idx]].DIRECTION}
                            btnText={texts['web_cms_buttons_save']}
                            onClick={onLanguageChange}
                            onRemove={onLanguageRemove}
                        />
                    ) )}
                </tbody>
            </table>
        </div>
    );
};

export default LanguagesManager;