import { 
    Categories, 
    CMSHome, 
    ContentTreks, 
    ContentUsers, 
    Login, 
    Parameters, 
    ReportsManager, 
    Translations 
} from "pages/CMS/screens";
import ContactEntries from "pages/CMS/screens/contacts";
import Alerts from "pages/CMS/screens/content/alerts";
import { Outlet } from "react-router-dom";


/* ================================================== */
/* ================= General Routes ================= */
/* ================================================== */
export const INDEX      = "/index";
export const NAVIGATION = {
    home: 'web_burger_menu_home',
    features: 'web_burger_menu_features', 
    gallery: 'web_burger_menu_gallery', 
    contact: 'web_burger_menu_contact'
};

/* ================================================== */
/* ========= Community Administator Routes ========== */
/* ================================================== */
export const ADMIN      = '/admin';
export const ADMIN_SUBS = {
    login: `${ADMIN}/login`,
    dashboard: `${ADMIN}/dashboard`,
    users: `${ADMIN}/users`,
    singleUser: `${ADMIN}/users/:profileId`,
    treks: `${ADMIN}/treks`,
    singleTrek: `${ADMIN}/treks/:trekId`,
};

/* ================================================== */
/* ======== Content Management System Routes ======== */
/* ================================================== */
export const CMS = "/cms";
export const generalDeclaration = `${CMS}/settings`;
export const root = `${CMS}/dashboard`;
export const content = `${CMS}/content`;
export const reports = `${CMS}/reports`;
export const website = `${CMS}/website`;
export const CMS_SUBS = {
    root: {
        element: Outlet,
        path: '',
        subs: {
            home: {
                path: ``,
                element: CMSHome
            },
            login: {
                path: `login`,
                element: Login
            }
        }
    },
    web: {
        element: Outlet,
        path: website,
        subs: {
            contacts: {
                path: `${website}/contacts`,
                element: ContactEntries
            }
        }
    },
    content:{
        element: Outlet,
        path: content,
        subs: {
            users: {
                path: `${content}/users`,
                element: ContentUsers
            },
            user: {
                hide: true,
                path: `${content}/users/:userId`,
                element: ContentUsers
            },
            treks: {
                path: `${content}/treks`,
                element: ContentTreks
            },
            trek: {
                hide: true,
                path: `${content}/treks/:trekId`,
                element: ContentTreks
            },
            alerts: {
                path: `${content}/alerts`,
                element: Alerts
            },
        }
    },
    reports:{
        element: Outlet,
        path: reports,
        subs: {
            users: {
                path: `${reports}/users`,
                element: ReportsManager
            },
            treks: {
                path: `${reports}/treks`,
                element: ReportsManager
            },
            comments: {
                path: `${reports}/comments`,
                element: ReportsManager
            },
        }
    },
    gd:{
        element: Outlet,
        path: generalDeclaration,
        subs: {
            translations: {
                path: `${generalDeclaration}/translations`,
                element: Translations
            },
            categories: {
                path: `${generalDeclaration}/categories`,
                element: Categories
            },
            parameters: {
                path: `${generalDeclaration}/parameters`,
                element: Parameters
            }
        }
    },
};