import React, { useState } from 'react';
import Api from 'api/requests';
import { 
    mdiAlertOctagonOutline, 
    mdiAlertOutline, 
    mdiInformationOutline, 
    mdiLink, 
    mdiPencil, 
    mdiTrashCanOutline 
} from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import { capitalize } from 'utilites';
import Actions from 'redux/actions';
import popupTypes from 'constants/popup-types';
import moment from 'moment';
import Switch from 'components/UI/Switch';


const AlertEntry = (props) => {
    const { 
        title = {}, 
        _id, 
        link, 
        type, 
        description = {},
        expiry,
        active = false,
    } = props;
    const dispatch = useDispatch();
    const selectedLanguage = useSelector(store => store?.cms?.selectedAlertLanguage) || 'en';
    const direction = useSelector(store => store.gd.translations[selectedLanguage].DIRECTION);
    const typeOptions = useSelector(store => store?.gd?.parameters?.alertTypes[type]);

    const removeHandler = () => {
        if(window.confirm('Are you sure you want to remove this alert?')){
            const props = {
                payload: { id: _id }
            }
            Api.removeAlert(props)
        }
    };
    
    const toggleActive = (e) => {
        const { checked } = e.target;
        const props = {
            payload: {
                id: _id,
                toggle: checked,
            }
        }

        Api.toggleAlert(props);
    };
    

    const editAlertHandler = () => {
        let date = moment(expiry);
        date = `${date.year()}-${date.month() + 1}-${('0'+date.date()).slice(-2)}`;

        const data = {  _id, link, title, description, type, expiry: date};
        console.log(date)
        dispatch(Actions.addPopup({
            type: popupTypes.ADD_ALERT,
            payload: {
                data
            }
        }));
    };

    const { backgroundColor = '', color = '' } = typeOptions;
    const dynamicStyles = {
        direction: direction,
        backgroundColor: backgroundColor + 'CC',
        color: color
    };

    const switchColors = {
        rail: {
            active: '#32CD32',
            inactive: '#D0F0C0'
        },
        thumb: {
            active: '#fff',
            inactive: '#fff'
        }
    };

    let date = moment(expiry);
    date = `${('0'+date.date()).slice(-2)}-${date.month() + 1}-${date.year()}`;
    return (
        <div className='alert-entry-wrapper' style={dynamicStyles}>
            <Icon path={getIcon[typeOptions.icon]} className='alert-icon' title={capitalize(type)}/>
            <div className='text-wrapper'>
                <div className='alert-title-wrapper'>
                    <div className='alert-headline'>
                        {title[selectedLanguage]}
                    </div>
                    <span className='expiry-date'>
                        Expires on{' '}{date}
                    </span>
                </div>
                <div className='alert-body'>
                    {description[selectedLanguage]}
                </div>
            </div>
            <div className='control-buttons'>
                {link && (
                    <a 
                        href={link} 
                        className='a-link' style={{color:color}}
                        target='_blank'
                        rel='noreferrer noopener'
                    >
                        <Icon path={mdiLink} className='control-icon link-icon' />
                    </a>
                )}
                <Icon path={mdiPencil} className='control-icon edit-icon' onClick={editAlertHandler} />
                <Icon path={mdiTrashCanOutline} className='control-icon remove-icon' onClick={removeHandler} />
                <Switch 
                    colors={switchColors}
                    isToggled={active} 
                    onToggle={toggleActive}
                    label={'Alert activity status'}
                    className='alert-entry-active-switch'
                    rounded 
                />
            </div>
        </div>
    );
};

export default AlertEntry;

const getIcon = {
    'alert-octagon-outline':  mdiAlertOctagonOutline,
    'alert-outline':  mdiAlertOutline,
    'information-outline':  mdiInformationOutline,
};
