import Api from 'api/requests';
import Button from 'components/UI/Button';
import FloatInput from 'components/UI/FloatInput';
import { CMS } from 'constants/routes';
import useLanguage from 'hooks/useLanguage';
import useTitle from 'hooks/useTitle';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { redirect, Navigate } from 'react-router-dom';
import { Validate } from 'utilites';
import './index.scss';


const Login = (props) => {
    useTitle(props);
    const texts = useLanguage();
    const userData = useSelector(store => store.userData);

    const [firstTry, setFirstTry] = useState(true);
    const [form, setForm] = useState({
        email: {
            value: "",
            isValid: false,
            errMsg: "",
            rules: ['email', 'required']
        },
        password: {
            value: '',
            isValid: false,
            errMsg: "",
            rules: ['required']
        }
    });

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        const updatedForm = JSON.parse(JSON.stringify(form));
        const validationObj = Validate(value, updatedForm[name].rules);


        updatedForm[name].isValid = validationObj.valid;
        updatedForm[name].errMsg = validationObj.msg;

        updatedForm[name].value = value;
        setForm(updatedForm);
    };
    
    const showError = (field) => {
        return !firstTry && !form[field].isValid;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const updatedForm = JSON.parse(JSON.stringify(form));
        let isFormValid = true;

        for(const key in updatedForm){
            const validationObj = Validate(updatedForm[key].value, updatedForm[key].rules);
            updatedForm[key].isValid = validationObj.valid;
            updatedForm[key].errMsg = validationObj.msg;

            if(!validationObj.valid){
                isFormValid = false;
            }
        }

        setFirstTry(false);
        setForm(updatedForm);
        if(!isFormValid){
            return;
        }

        const onSuccess = () => {
            redirect(CMS);
        }
        

        const props = {
            onSuccess,
            payload: {
                isCms: true,
                email: form.email.value,
                password: form.password.value
            }
        };

        Api.logIn(props);

    };
    
    
    if(userData) {
        return <Navigate to={CMS} replace />
    }
    return (
        <div className='cms-login-wrapper'>
            <form className='cms-login-fields-wrapper' onChange={onFieldChange} onSubmit={onSubmit}>
                <FloatInput
                        value={form.email.value}
                        errMsg={form.email.errMsg}
                        isValid={form.email.isValid}
                        label={texts['field_email']}
                        name={'email'}
                        showError={showError('email')}
                />
                <FloatInput
                    value={form.password.value}
                    errMsg={form.password.errMsg}
                    isValid={form.password.isValid}
                    label={texts['field_password']}
                    name={'password'}
                    type={'password'}
                    showError={showError('password')}
                />
                <Button>{texts['web_cms_buttons_login']}</Button>
            </form>
        </div>
    );
};

export default Login;