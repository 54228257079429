import "./Button.scss";

const Button = (props) => {
    const { onClick = () => {}, children, className = '' } = props;
    return (
        <button
            className={`button-wrapper ${className}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
