import React, { useState }  from 'react';

/* ---------- Functions ---------- */
import { deepClone }    from 'utilites';

/* ---------- Redux ---------- */
import { useDispatch, useSelector } from 'react-redux';
import Actions                      from 'redux/actions';

/* ---------- Constants ---------- */
import * as Notifications   from 'constants/notification-types';

/* ---------- Assets ---------- */
import './index.scss';
import { AiOutlineDelete, AiOutlineSave }   from 'react-icons/ai';
import useLanguage from 'hooks/useLanguage';

const ProtectedParameters = () => {
    const texts = useLanguage();
    const dispatch = useDispatch();
    const gdParams = useSelector(store => store.gd.parameters);
    const parameters = Object.values(useSelector(store => store.gd.parameters.protectedParameters));
    const filtered = parameters.filter(para => para !== 'protectedParameters');
    const [newParameter, setNewParameter] = useState("");

    const onChange = (e) => {
        const { value } = e.target;
        setNewParameter(value);
    }
    
    const handleAdd = () => {
        if(newParameter !== ''){
            const formated = newParameter.trim().replace(' ', '_');
            let updatedParameters = [...parameters];
            updatedParameters.push(formated);
            updateParameters(updatedParameters);
            dispatch(Actions.addNotification({
                title: "Success",
                text: `${newParameter} added to the Protected Parameters!`,
                status: Notifications.SUCCESS,
                duration: 4000,
            }));
            setNewParameter('');
        } else {
            dispatch(Actions.addNotification({
                title: "Error",
                text: `Key is empty`,
                status: Notifications.ERROR,
                duration: 4000,
            }));
        }
    }

    const updateParameters = (newParams) => {
        const updatedParameters = deepClone(gdParams);
        updatedParameters.protectedParameters = newParams;
        dispatch(Actions.setGeneralDeclaration({ parameters: updatedParameters }));
    }

    const handleRemove = (key) => {
        let updatedParameters = parameters.filter(para => para !== key);
        updateParameters(updatedParameters);
        dispatch(Actions.addNotification({
            title: "Success",
            text: `${key} removed from the Protected Parameters!`,
            status: Notifications.SUCCESS,
            duration: 4000,
        }));
    }
    
    return (
        <div className='protected-parameters-wrapper'>
            <h2>{texts['web_cms_parameters_protected_title']}</h2>
            <table>
                <thead>
                    <tr>
                        <th>
                            {texts['web_cms_table_column_key']}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input type={'text'} value={newParameter} onChange={onChange} />
                            <AiOutlineSave className='remove-btn add' onClick={handleAdd}/>
                        </td>
                    </tr>
                    {filtered.map(key => (
                        <tr key={`protected_parameter_${key}`}>
                            <td>
                                <input type={'text'} value={key} readOnly/>
                                <AiOutlineDelete className='remove-btn' onClick={handleRemove.bind(this, key)}/>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProtectedParameters;