import React, { useState }  from 'react';

/* ---------- Components ---------- */
import Button   from 'components/UI/Button';

/* ---------- Assets ---------- */
import { AiOutlineWarning } from 'react-icons/ai'
import useLanguage from 'hooks/useLanguage';


const TableRow = (props) => {
    const texts = useLanguage();
    const { id, code, name, direction, btnText, onClick = () => {}, onRemove } = props;
    const [fields, setFields] = useState({code, name});
    const [localDirection, setLocalDirection] = useState(direction);
    

    const localOnChange = (e) => {
        const { name, value } = e.target;
        setFields(prev => { 
            return {...prev, [name]:value };
        })
    }
    
    const showUnsavedIndicator = (field) => {
        let originalValue;
        if(field === 'code') originalValue = code;
        if(field === 'name') originalValue = name;
        if(field === 'id') originalValue = id;
        if(field === 'direction') return localDirection !== direction && direction !== '';
        return fields[field] !== originalValue;
    }

    const localOnClick = () => {
        const withDirection = {
            ...fields,
            direction: localDirection
        }
        onClick(id, withDirection);
        setFields({ code, name });
        setLocalDirection(direction)
    }
    

    const onDirectionChange = (e) => {
       const { value } = e.target;
       setLocalDirection(value);
    }
    
    

    return (
        <tr>
            <td className='number'>
                <input type={'text'} disabled readOnly value={id} className='id'/>
            </td>
            {Object.keys(fields).map((key, id) => (
                <td key={`language-table-${key}-${id}`}>
                     <input type={key === 'id' ? "number":"text"} onChange={localOnChange} name={key} className={key === 'id' ? "id":""} value={fields[key]}/>
                    {showUnsavedIndicator(key) && <AiOutlineWarning color='#ff0000' className='indicator'/>}
                </td>
            ))}
            <td>
                <select name='direction' defaultValue={localDirection === '' ? 'ltr':localDirection} onChange={onDirectionChange}>
                    <option value={'ltr'}>{texts['web_cms_languages_direction_ltr']}</option>
                    <option value={'rtl'}>{texts['web_cms_languages_direction_rtl']}</option>
                </select>
                {showUnsavedIndicator('direction') && <AiOutlineWarning color='#ff0000' className='indicator'/>}
            </td>
            <td>
                <Button onClick={localOnClick}>{btnText}</Button>
                {onRemove && <Button onClick={() => onRemove(id)} className="remove-btn">{texts['web_cms_buttons_remove']}</Button>}
            </td>
        </tr>
    );
};

export default TableRow;